import CartResolver from './cart';

const resolvers = [CartResolver];
const defaultResolvers = {
  Muation: {},
  Query: {},
};

resolvers.forEach(({ Mutation, Query }) => {
  if (Mutation) defaultResolvers.Mutation = { ...defaultResolvers.Mutation, ...Mutation };
  if (Query) defaultResolvers.Query = { ...defaultResolvers.Query, ...Query };
});

export default defaultResolvers;
