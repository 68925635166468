import gql from 'graphql-tag';

export const SUPPORT_CONTACT_MUTATION = gql`
  mutation SupportContact($topic: String!, $request: String!, $error: String) {
    supportContact(topic: $topic, request: $request, error: $error) {
      success
    }
  }
`;

export const RECLAMATION_MUTATION = gql`
  mutation Reclamation(
    $orderId: String!
    $reason: String!
    $returnArticles: [returnArticle]!
    $pictures: [Upload]
    $comment: String
  ) {
    reclamation(
      orderId: $orderId
      reason: $reason
      returnArticles: $returnArticles
      pictures: $pictures
      comment: $comment
    ) {
      success
    }
  }
`;
