/* eslint react/no-array-index-key: 0 */
/* eslint func-names: 0 */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { Button, Form, Input } from 'formik-semantic-ui';
import * as Yup from 'yup';
import classnames from 'classnames';
import { toIdMap } from '../helpers/select-articles';
import alreadyInCartIcon from '../assets/already-in-cart-icon.svg';
import refreshCartIcon from '../assets/refresh-cart-icon.svg';
import { euro } from '../helpers/currency';

// Add custom yup method to validate order numbers
Yup.addMethod(Yup.number, 'divisibleBy', function (increment) {
  return this.test('divisibleBy', function (value) {
    if (value % increment !== 0) {
      const nextValid = value ? Math.floor((value + increment) / increment) * increment : increment;
      return this.createError({
        message: `Diese Menge passt nicht zur angegebenen Gebindegrösse "${increment}". Wählen Sie z.B. ${nextValid} aus.`,
      });
    }
    return true;
  });
});

class ArticleForm extends Component {
  constructor(props) {
    super(props);
    const { articles } = props;

    this.state = {
      allArticles: [...articles],
      initialValues: this.buildInitialValues(articles),
      deletedArticles: {},
    };

    // Generate validation schema
    this.validationSchema = this.createSchema(articles);
  }

  // Increment order number relative to increment value
  increment = ({ articleId, increment, setFieldValue, direction, values }) => {
    increment = Number(increment);

    this.setState(
      ({ initialValues }) => {
        const value = Number(values[articleId]);
        initialValues[articleId] = this.addOrSubstract(value, increment, direction);

        return { initialValues };
      },
      () => setFieldValue(articleId, this.state.initialValues[articleId])
    );
  };

  addOrSubstract = (value, increment, direction) => {
    if (direction === 'plus') {
      return value ? Math.floor((value + increment) / increment) * increment : increment;
    }

    return value ? Math.ceil((value - increment) / increment) * increment : value;
  };

  // Re-render form with new order values
  updateArticleAmount = (articleNumber, value) => {
    this.setState(({ initialValues }) => {
      initialValues[articleNumber] = value;
      return { initialValues };
    });
  };

  deleteArticle = (articleId, setFieldValue) => {
    this.setState(
      ({ deletedArticles, initialValues }) => {
        deletedArticles[articleId] = true;
        initialValues[articleId] = 0;

        return { deletedArticles, initialValues };
      },
      () => setFieldValue(articleId, 0)
    );
  };

  handleSubmit = (values, formikApi) => {
    formikApi.setSubmitting(false);
  };

  createTable = ({ values, cartArticles, articles, filteredArticles, setFieldValue, errors }) => {
    // create empty table (array of rows)
    const rows = [];

    articles.forEach(
      ({
        containerId,
        articleId,
        stockIncrement,
        unitName,
        price,
        name,
        origin,
        description,
        taxType,
      }) => {
        const error = errors[articleId];
        const amountInCart = cartArticles[articleId] ? cartArticles[articleId].amount : null;
        // create empty row (array of cells)
        const cells = [];
        const value = values[articleId];
        const isDifferentThanCart = amountInCart !== value;
        const articleTotal = `${euro(value * price)} €`;

        cells.push(
          <Table.Cell className="product-name" key={`${containerId}.name`}>
            <div>{name}</div>
            <div className="origin cart-label no-shop">{origin}</div>
          </Table.Cell>,
          <Table.Cell
            className="origin no-mobile no-cart no-checkout"
            key={`${containerId}.origin`}
          >
            {origin}
          </Table.Cell>,
          <Table.Cell
            className="description no-cart no-checkout"
            key={`${containerId}.description`}
          >
            {description}
          </Table.Cell>,
          <Table.Cell className="produce-price" key={`${containerId}.price`}>
            <div>
              {euro(price)} €/{unitName}
              <span>{taxType === 'STANDARD' ? '*' : ''}</span>
            </div>
            <div className="cart-label">zzgl. {taxType === 'STANDARD' ? '19%' : '7%'} MwSt.</div>
          </Table.Cell>,
          <Table.Cell className="quantity" key={`${containerId}.stockIncrement`}>
            {stockIncrement} {unitName}
          </Table.Cell>,
          <Table.Cell className="spacer" key={`${containerId}.errors`}>
            {amountInCart && !error && !isDifferentThanCart && (
              <span className="amount-in-cart">
                <img src={alreadyInCartIcon} alt="Produkt liegt im Warenkorb" />
              </span>
            )}
            {isDifferentThanCart && amountInCart && !error && (
              <span className="refresh-cart ">
                <img src={refreshCartIcon} alt="Warenkorb aktualisieren" />
              </span>
            )}
            <span className="custom-error">{error}</span>
          </Table.Cell>,
          <Table.Cell className="orderinput" key={`${containerId}.orderAmount`}>
            <div className="order-input-group">
              <Button
                className="increment-button"
                disabled={this.props.isCheckout}
                onClick={() =>
                  this.increment({
                    articleId,
                    setFieldValue,
                    values,
                    increment: stockIncrement,
                    direction: 'minus',
                  })
                }
              >
                <span>-</span>
              </Button>
              <Input
                inputProps={{
                  disabled: this.props.isCheckout,
                  className: 'order-input',
                  label: { basic: true, content: unitName },
                  labelPosition: 'right',
                  tabIndex: 2,
                  onChange: (e, { name: fieldName, value: fieldValue }) => {
                    this.updateArticleAmount(fieldName, Number(fieldValue));
                  },
                }}
                name={articleId}
              />
              <Button
                className="increment-button"
                disabled={this.props.isCheckout}
                onClick={() =>
                  this.increment({
                    articleId,
                    setFieldValue,
                    values,
                    increment: stockIncrement,
                    direction: 'plus',
                  })
                }
              >
                <span>+</span>
              </Button>
            </div>
          </Table.Cell>,
          <Table.Cell key={`${containerId}.articleTotal`} className="no-shop article-total">
            {articleTotal}
          </Table.Cell>,
          <Table.Cell
            key={`${containerId}.delete`}
            className="no-shop delete-article"
            hidden={this.props.isCheckout}
          >
            <Button
              className="no-shop"
              onClick={() => this.deleteArticle(articleId, setFieldValue)}
            >
              x
            </Button>
          </Table.Cell>
        );
        // Create the table and add the cells
        rows.push(
          <Table.Row
            className={classnames({
              'has-error': error,
              'has-changed': amountInCart && !error && isDifferentThanCart,
              isDeleted: !!this.state.deletedArticles[articleId],
            })}
            key={articleId}
            hidden={
              this.props.isCart
                ? false
                : !filteredArticles.find((article) => article.articleId === articleId)
            }
          >
            {cells}
          </Table.Row>
        );
      }
    );

    return rows;
  };

  buildInitialValues(articles, cartArticles = {}) {
    return articles.reduce((result, { articleId, amount }) => {
      if (amount) result[articleId] = amount;
      else {
        result[articleId] = cartArticles[articleId] ? cartArticles[articleId].amount : 0;
      }

      return result;
    }, {});
  }

  createSchema(articles) {
    const yupSchema = articles.reduce((result, article) => {
      const { articleId, stockIncrement } = article;
      result[articleId] = Yup.number()
        .typeError('Der eingegebene Wert ist keine valide Zahl')
        .divisibleBy(stockIncrement);
      return result;
    }, {});

    return Yup.object().shape(yupSchema);
  }

  render() {
    const { allArticles, deletedArticles } = this.state;
    const { articles, cartArticles, filteredArticles, isCart } = this.props;
    const idCartArticles = toIdMap(cartArticles);
    const initialValues = this.buildInitialValues(articles, idCartArticles);

    return (
      <Form
        initialValues={initialValues}
        validationSchema={isCart ? this.createSchema(articles) : this.validationSchema}
        onSubmit={this.handleSubmit}
        validateOnChange
        enableReinitialize
        render={({ values, setFieldValue, errors, validateForm, resetForm }) => (
          <>
            <Table>
              <Table.Header className="no-mobile">
                <Table.Row className="table-header">
                  <Table.HeaderCell className="product-name">Produktname</Table.HeaderCell>
                  <Table.HeaderCell className="no-cart origin no-checkout">
                    Herkunft
                  </Table.HeaderCell>
                  <Table.HeaderCell className="no-cart properties no-checkout">
                    Eigenschaften
                  </Table.HeaderCell>
                  <Table.HeaderCell className="produce-price">Preis / Einheit</Table.HeaderCell>
                  <Table.HeaderCell className="quantity">Gebinde</Table.HeaderCell>
                  <Table.HeaderCell className="no-checkout spacer" />
                  <Table.HeaderCell className="amount">ausgewählte Menge</Table.HeaderCell>
                  <Table.HeaderCell className="no-shop sum">Summe</Table.HeaderCell>
                  <Table.HeaderCell className="no-shop no-checkout" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.createTable({
                  cartArticles: idCartArticles,
                  values,
                  articles,
                  filteredArticles,
                  setFieldValue,
                  errors,
                })}
              </Table.Body>
            </Table>
            {this.props.render({
              values: _.pickBy(values, (amount, articleId) => {
                const cartArticle = idCartArticles[articleId];
                // only return values that are different from cart
                if (cartArticle && cartArticle.amount === amount) return false;

                // return any value greater than 0 or if in cart so we can remove articles with
                // amount 0 from cart in resoler
                return cartArticle || amount > 0;
              }),
              isValid: _.size(errors) === 0,
              validate: async () => {
                const validationErrors = await validateForm();
                const isValid = _.size(validationErrors) === 0;

                return isValid;
              },
              hasChanges: _.some(values, (value, articleId) => {
                const cartArticle = idCartArticles[articleId];
                const cartValue = cartArticle ? cartArticle.amount : 0;

                return value !== cartValue;
              }),
              onSuccess: () => {
                const resetValues = this.buildInitialValues(allArticles);
                this.setState(() => ({ initialValues: resetValues }));
                resetForm(resetValues);
              },
              resetDeleted: () => this.setState({ deletedArticles: {} }),
              deletedArticles,
            })}
          </>
        )}
      />
    );
  }
}

ArticleForm.propTypes = {
  render: PropTypes.func,
  articles: PropTypes.arrayOf(PropTypes.object),
  cartArticles: PropTypes.arrayOf(PropTypes.object),
  filteredArticles: PropTypes.arrayOf(PropTypes.object),
  isCart: PropTypes.bool,
  isCheckout: PropTypes.bool,
};

ArticleForm.defaultProps = {
  render: () => {},
  articles: [],
  cartArticles: [],
  filteredArticles: [],
  isCart: false,
  isCheckout: false,
};

export default ArticleForm;
