import { calcTotalPrice } from './totals';

export const trackPage = (page) => {
  // GTAG
  // eslint-disable-next-line no-unused-expressions
  window.gtag &&
    window.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: `${window.location.origin}${page}`,
      page_path: page,
    });

  // GTM
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: {
      title: document.title,
      location: `${window.location.origin}${page}`,
      path: page,
    },
  });

  // Rudderstack
  // eslint-disable-next-line no-unused-expressions
  window.rudderLoaded &&
    window.rudderanalytics.page(
      null,
      document.title,
      {
        path: page,
        referrer: document.referrer,
        search: '',
        title: document.title,
        url: `${window.location.origin}${page}`,
        source: typeof window.source === 'function' ? window.source() : '',
      },
      {
        integrations: window.getRSIntegrations(),
      }
    );
};

const rudderTrack = (...args) => {
  const tempArgs = args;
  tempArgs[1] = {
    ...(tempArgs[1] || {}),
    origin_app: 'Business',
    event_timestamp: Date.now(),
    relevant_business_channel: 'B2B',
  };
  if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
    // eslint-disable-next-line
    if (tempArgs[2]) tempArgs[3] = tempArgs[2];
    tempArgs[2] = {
      integrations: {
        All: false,
        'Customer IO': true,
      },
    };
    window.rudderJobs = window.rudderJobs || [];
    window.rudderJobs.push([...tempArgs]);
    return;
  }
  // eslint-disable-next-line
  if (tempArgs[2]) tempArgs[3] = tempArgs[2];
  tempArgs[2] = {
    integrations: window.getRSIntegrations(),
  };
  window.rudderanalytics.track(...tempArgs);
};

const deconstructArray = (array) => {
  const deconstructedArray = {};
  array.forEach((object) => {
    Object.keys(object).forEach((key) => {
      if (!deconstructedArray[`products_${key}`]) deconstructedArray[`products_${key}`] = [];
      deconstructedArray[`products_${key}`].push(object[key]);
    });
  });
  return deconstructedArray;
};

export const trackViewCart = (cartItems) => {
  const productsData = [];
  cartItems.forEach((i, index) => {
    productsData.push({
      product_id: i.articleId,
      sku: '',
      name: i.name,
      price: i.price,
      position: index + 1,
      category: '',
      quantity: i.amount,
    });
  });

  rudderTrack('Cart Viewed', {
    cart_id: '',
    products: productsData,
    ...deconstructArray(productsData),
  });
};

export const trackAddToCart = (product, quantity, position = 1, cartData = '') => {
  window.gtag &&
    window.gtag('event', 'add_to_cart', {
      items: [
        {
          id: product.articleId,
          name: product.name,
          price: product.price,
          quantity,
        },
      ],
    });

  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    rudderTrack(
      'Product Added',
      {
        product_id: product.articleId,
        sku: '',
        category: '',
        name: product.name,
        brand: '',
        variant: '',
        price: product.price,
        quantity,
        coupon: '',
        position,
        cart_data: cartData,
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const trackRemoveFromCart = (product, quantity, position = 1) => {
  window.gtag &&
    window.gtag('event', 'remove_from_cart', {
      items: [
        {
          id: product.articleId,
        },
      ],
    });

  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    rudderTrack(
      'Product Removed',
      {
        product_id: product.articleId,
        sku: '',
        category: '',
        name: product.name,
        brand: '',
        variant: '',
        price: product.price,
        quantity,
        coupon: '',
        position,
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const trackPurchase = (transaction, cartData) => {
  return new Promise((resolve) => {
    let totalValue = 0;
    const articlesData = [];
    cartData.articles.forEach((article) => {
      articlesData.push({
        id: article.articleId,
        name: article.name,
        price: article.price,
        quantity: article.amount,
      });
      totalValue += article.price * article.amount;
    });

    window.gtag &&
      window.gtag('event', 'purchase', {
        transaction_id: transaction.id,
        method: transaction.source,
        payment_type: transaction.source,
        value: totalValue,
        currency: 'EUR',
        tax: 1.07,
        items: articlesData,
      });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'transaction',
      ecommerce: {
        purchase: {
          actionField: {
            id: transaction.id,
            revenue: totalValue,
            tax: '1.07',
          },
          products: articlesData,
        },
      },
    });

    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }

    const productsData = [];

    cartData.articles.forEach((i, index) => {
      productsData.push({
        product_id: i.articleId,
        sku: '',
        name: i.name,
        price: i.price,
        position: index + 1,
        category: '',
        quantity: i.amount,
        tax_type: i.taxType,
      });
    });
    const { grossTotal, netTotal, taxTotal } = calcTotalPrice(cartData.articles);

    rudderTrack(
      'Order Completed',
      {
        checkout_id: transaction.id,
        order_id: transaction.id,
        value: grossTotal.toFixed(2),
        revenue: netTotal.toFixed(2),
        tax: taxTotal.toFixed(2),
        currency: 'EUR',
        products: productsData,
        ...deconstructArray(productsData),
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const identify = async (userId) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      // TODO Log error for rudder not loaded
      resolve(false);
    }
    window.rudderanalytics.identify(userId, {}, {}, () => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });
  });
};

export const redirectTo = (to, params = '') => {
  let search = params || window.location.search;
  if (to.includes('?') && search.includes('?')) {
    search = search.replace('?', '&');
  }
  window.location.assign(`${to}${search}`);
};

export const trackProductListViewed = (products, region) => {
  const productsData = [];
  products.forEach((i, index) => {
    productsData.push({
      product_id: i.articleId,
      sku: '',
      name: i.name,
      price: i.price,
      position: index + 1,
      quantity: 1,
      category: '',
    });
  });
  rudderTrack('Product List Viewed', {
    list_id: region,
    category: region,
    products: productsData,
    ...deconstructArray(productsData),
  });
};

export const trackCheckoutStarted = (cartData, checkoutState) => {
  return new Promise((resolve) => {
    if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) {
      resolve(false);
    }
    const productsData = [];
    cartData.cartItems.forEach((i, index) => {
      productsData.push({
        product_id: i.articleId,
        sku: '',
        name: i.name,
        price: i.price,
        position: index + 1,
        category: '',
        quantity: i.quantity,
        tax_type: i.taxType,
      });
    });
    const value = cartData.checkout.summary.itemTotal.rawAmount;
    const revenue =
      cartData.checkout.summary.itemTotal.rawAmount -
      cartData.checkout.summary.taxTotal.rawTotalAmount;
    const tax = cartData.checkout.summary.taxTotal.rawTotalAmount;

    rudderTrack(
      'Checkout Started',
      {
        value,
        revenue: revenue.toFixed(2),
        tax: tax.toFixed(2),
        coupon: checkoutState.promoCode,
        currency: 'EUR',
        products: productsData,
        interval: 'week',
        interval_count: checkoutState.subscriptionInterval,
        ...deconstructArray(productsData),
      },
      () => setTimeout(() => resolve(true), 300)
    );
  });
};

export const getAnonymousId = () => {
  if (!window.rudderanalytics || !window.rudderLoaded || !window.rudderReady) return '';
  return window.rudderanalytics.getAnonymousId();
};
