import React from 'react';
import PropTypes from 'prop-types';
import herzkartoffel from '../assets/herzkartoffel.png';
import successIcon from '../assets/success_icon_big_1x.png';
import splashes from '../assets/splashes.svg';

const FoodSavedCounter = ({ totalSaved }) => (
  <div className="foodsavedcounter widget">
    <div className="content">
      <img src={splashes} alt="" className="splashes top-right" />
      <img src={herzkartoffel} alt="Herz-Kartoffel" className="potato" />
      <div className="text-wrapper">
        <span className="widget-intro boldtext">Mit Ihrer Bestellung werden</span>
        <span className="widget-amount">{totalSaved.replace('.', ',')} kg</span>
        <span className="widget-outro boldtext">Lebensmittel gerettet!</span>
      </div>
      <img src={successIcon} alt="Bestellung erfolgreich" className="successIcon" />
      <img src={splashes} alt="" className="splashes bottom-left" />
    </div>
  </div>
);

FoodSavedCounter.propTypes = {
  totalSaved: PropTypes.string.isRequired,
};

export default FoodSavedCounter;
