import React from 'react';
import PropTypes from 'prop-types';
import { Header, Loader } from 'semantic-ui-react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import Cart from './Cart';
import { GET_CURRENT_CART, COMMIT_CART_MUTATION } from '../queries/ProductQueries';
import { GET_USER } from '../queries/UserQueries';
import { client } from '../index';

const CartContainer = ({ onHide }) => {
  const { data: userData, loading: userLoading } = useQuery(GET_USER);
  const { data: cartData, loading: cartLoading } = useQuery(GET_CURRENT_CART);

  const saveCart = async ({ validate, selectedArticles, netTotal, resetDeleted }) => {
    const isValidAfter = await validate();
    if (isValidAfter) {
      await client.mutate({
        mutation: COMMIT_CART_MUTATION,
        variables: {
          articles: _.map(selectedArticles, (a) => a),
          total: netTotal,
        },
      });
      onHide();
      resetDeleted();
    }
  };

  const submitCart = async ({ validate, selectedArticles, netTotal, resetDeleted, history }) => {
    const isValidAfter = await validate();
    if (isValidAfter) {
      await client.mutate({
        mutation: COMMIT_CART_MUTATION,
        variables: {
          articles: _.map(selectedArticles, (a) => a),
          total: netTotal,
        },
      });
      resetDeleted();
    }

    const billingAddresses = userData.currentUser.addresses.filter(
      (address) => address.invoiceAddress
    );
    if (billingAddresses.length < 1) history.push('/billing');
    else history.push('/checkout');

    onHide();
  };

  if (userLoading || cartLoading) return <Loader active inline="centered" />;
  return (
    <>
      <div className="cart-header">
        <Header as="h1" className="header--is-handwritten">
          Warenkorb
        </Header>
      </div>
      <Cart
        articles={cartData.cart.articles}
        closeButtonText="Einkauf fortsetzen"
        submitButtonText="zur Kasse"
        onClose={saveCart}
        onSubmit={submitCart}
      />
    </>
  );
};

CartContainer.propTypes = {
  onHide: PropTypes.func.isRequired,
};

export default CartContainer;
