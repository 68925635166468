import gql from 'graphql-tag';

const articleFragment = gql`
  fragment articleValues on Article {
    articleId
    containerNumber
    containerId
    articleCategoryId
    name
    origin
    price
    unitName
    containerQuantity
    produceQuantity
    stockIncrement
    description
    taxType
    articleNetWeight
  }
`;

export const SHOP_DATA_QUERY = gql`
  {
    businessArticles {
      ...articleValues
    }
    articleCategories {
      id
      name
    }
  }
  ${articleFragment}
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation AddToCart($articles: [Article]) {
    addToCart(articles: $articles) @client {
      cart {
        articles {
          ...articleValues
          amount
        }
        total
      }
    }
  }
  ${articleFragment}
`;

export const COMMIT_CART_MUTATION = gql`
  mutation CommitCart($articles: [Article], $total: Int) {
    commitCart(articles: $articles, total: $total) @client {
      cart {
        articles {
          ...articleValues
          amount
        }
        total
      }
    }
  }
`;

export const GET_CURRENT_CART = gql`
  {
    cart @client {
      articles {
        ...articleValues
        amount
      }
      total
    }
  }
  ${articleFragment}
`;

export const CATEGORIES_QUERY = gql`
  {
    articleCategories {
      id
      name
    }
  }
`;
