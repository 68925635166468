import _ from 'lodash';

export const calcTotalPrice = (articles) => {
  return _.reduce(
    articles,
    ({ netTotal, taxTotal }, { amount, price, taxType }) => {
      const articleNetTotal = amount * price;
      const taxPercentage = taxType === 'REDUCED' ? 0.07 : 0.19;
      const articleTaxtotal = articleNetTotal * taxPercentage;
      netTotal += amount * price;
      taxTotal += articleTaxtotal;

      return {
        grossTotal: netTotal + taxTotal,
        netTotal,
        taxTotal,
      };
    },
    {
      netTotal: 0,
      grossTotal: 0,
      taxTotal: 0,
    }
  );
};

// TODO: account for orderItem articles, which do not track net weight.
export const calcTotalWeight = (articles) => {
  const total = articles.reduce((acc, a) => {
    if (a.articleNetWeight) {
      acc += a.articleNetWeight * a.amount;
    } else {
      acc += 0.83 * a.amount;
    }
    return acc;
  }, 0);
  return Number(total).toFixed(2);
};
