import React from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';
import Auth from '../services/AuthService';
import config from '../config';

const Login = () => {
  const [cookies] = useCookies();

  if (cookies.token) {
    Auth.authenticateUser(cookies.token);
    return <Redirect to="/shop" />;
  }

  return process.env.NODE_ENV === 'development' ? (
    <div style={{ margin: 'auto', fontSize: '1.5em' }}>
      <p>
        Application is currently in development mode. When in production, this page redirects to{' '}
        <strong>portal.querfeld.bio</strong>.
        <br />
        <a href="http://localhost:3000">Click here if running portal locally.</a>
      </p>
    </div>
  ) : (
    <>{window.location.assign(config.portal.baseURL)}</>
  );
};

export default Login;
