import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Rating, Button, TextArea } from 'semantic-ui-react';
import { client } from '../index';
import { GET_GREETING_DATA } from '../queries/UserQueries';
import { CREATE_REVIEW } from '../queries/ReviewQueries';

const ReviewModal = ({ defaultOpen, user }) => {
  const [open, setOpen] = useState(defaultOpen);
  const [submitting, setSubmitting] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [ratings, setRatings] = useState({});
  const { recentOrderId } = user;

  const setRating = (ratingType, value) => {
    const newRatings = ratings;
    newRatings[ratingType] = value;
    setRatings(newRatings);
    if (ratingType !== 'orderRating') setIsEdited(true);
  };

  const handleSubmit = async () => {
    if (submitting) return;
    setSubmitting(true);
    const variables = {
      ...ratings,
      ignored: !ratings.orderRating,
      salesOrderId: user.recentOrderId,
      salesOrderNumber: user.recentOrderNumber,
      customerId: user.customerId,
      customerNumber: user.customerNumber,
    };
    try {
      await client.mutate({
        mutation: CREATE_REVIEW,
        variables,
        refetchQueries: [{ query: GET_GREETING_DATA }],
      });
      if (ratings.orderRating) {
        setReviewStatus('success');
        setTimeout(() => setOpen(false), 1500);
      } else {
        setOpen(false);
      }
    } catch (e) {
      throw new Error(e);
    }
  };

  const basicReview = (
    <>
      <Modal.Header className="simple-rating-header">
        Bewerten Sie Ihre <br />
        Bestellung #{user.recentOrderNumber}
      </Modal.Header>
      <Modal.Content className="simple-rating-content">
        <Modal.Description>
          <p>
            Vielen Dank für Ihre Bestellung am{' '}
            {moment(`${user.recentOrderDate}`, 'x').format('DD.MM.YY')}.<br />
            Wir würden uns freuen, wenn Sie Ihre letzte Bestellung bewerten würden:
          </p>
          <Rating
            disabled={submitting}
            key={`${recentOrderId}.orderRating`}
            defaultRating={0}
            maxRating={5}
            size="large"
            onRate={(e, data) => {
              setRating('orderRating', data.rating);
              if (data.rating < 4) setReviewStatus('fullReview');
              else handleSubmit();
            }}
          />
        </Modal.Description>
        <Modal.Actions className="simple-rating-actions">
          <Button onClick={() => setReviewStatus('fullReview')} className="button2text">
            Detailliertes Feedback geben
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </>
  );

  const fullReview = (
    <>
      <Modal.Header>Das können wir besser - mit Ihrem Feedback:</Modal.Header>
      <Modal.Content>
        <Modal.Description className="ui form">
          <span className="label">Wurde die verabredete Lieferzeit eingehalten?</span>
          <Rating
            key={`${recentOrderId}.deliveryTimeRating`}
            defaultRating={0}
            maxRating={5}
            size="large"
            onRate={(e, data) => setRating('deliveryTimeRating', data.rating)}
          />
          <span className="label">Ist die Ware vollständig?</span>
          <Rating
            key={`${recentOrderId}.deliveryCompletionRating`}
            defaultRating={0}
            maxRating={5}
            size="large"
            onRate={(e, data) => setRating('deliveryCompletionRating', data.rating)}
          />
          <span className="label">Ist die Ware frisch?</span>
          <Rating
            key={`${recentOrderId}.freshnessRating`}
            defaultRating={0}
            maxRating={5}
            size="large"
            onRate={(e, data) => setRating('freshnessRating', data.rating)}
          />
          <span className="label">Besonderheiten wie auf dem Angebot angekündigt? </span>
          <Rating
            key={`${recentOrderId}.descriptionRating`}
            defaultRating={0}
            maxRating={5}
            size="large"
            onRate={(e, data) => setRating('descriptionRating', data.rating)}
          />
          <span className="label">Was wir noch wissen sollten:</span>
          <TextArea
            key={`${recentOrderId}.ratingText`}
            onBlur={(e) => setRating('ratingText', e.target.value)}
          />
        </Modal.Description>
        <Modal.Actions>
          <Button primary onClick={handleSubmit} disabled={!isEdited || submitting}>
            Abschicken
          </Button>

          <Button secondary onClick={handleSubmit} disabled={submitting} className="button2text">
            Kein Feedback geben
          </Button>
        </Modal.Actions>
        <div className="footer">
          <span>
            Die Qualität der Ware war nicht in Ordnung?
            <br />
            Falls Sie Ihre Ware reklamieren wollen, finden Sie unter folgendem Link unser <br />
            <Link to="/reclamation" onClick={handleSubmit} disabled={submitting}>
              Reklamationsformular
            </Link>
            .
          </span>
        </div>
      </Modal.Content>
    </>
  );

  const success = (
    <Modal.Content className="feedbacksuccess">
      <h3>Vielen Dank für Ihr Feedback!</h3>
    </Modal.Content>
  );

  let modalContent;
  switch (reviewStatus) {
    case 'fullReview':
      modalContent = fullReview;
      break;
    case 'success':
      modalContent = success;
      break;
    default:
      modalContent = basicReview;
  }

  return (
    <Modal
      onClose={handleSubmit}
      open={open}
      className="notification-box rating extended-rating"
      closeIcon
    >
      {modalContent}
    </Modal>
  );
};

ReviewModal.propTypes = {
  defaultOpen: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    customerId: PropTypes.string,
    customerNumber: PropTypes.string,
    recentOrderDate: PropTypes.string,
    recentOrderId: PropTypes.number,
    recentOrderNumber: PropTypes.string,
  }).isRequired,
};

export default ReviewModal;
