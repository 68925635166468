/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Card className="generic-message error">
            <Card.Content>
              <Header as="h1">Es ist ein Fehler aufgetreten.</Header>{' '}
              <div className="greytext">
                Bitte aktualisieren Sie die Seite <br />
                oder melden Sie das Problem unten.
              </div>{' '}
              <Link
                to={{
                  pathname: '/contact',
                  state: { errorData: this.state, contactType: 'shop_bug' },
                }}
                onClick={() => this.setState({ error: null, errorInfo: null })}
                className="backbutton"
              >
                Absturz melden
              </Link>
            </Card.Content>
          </Card>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
