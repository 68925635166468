import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/apm';
import { ApolloLink } from 'apollo-link';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-common';
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo';
import { onError } from 'apollo-link-error';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import Auth from './services/AuthService';
import resolvers from './resolvers';
import config from './config';

const { api } = config;

Sentry.init({
  dsn: config.sentryURL,
  integrations: [new Integrations.Tracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

const uploadLink = createUploadLink({
  uri: `${api.baseURL}/graphql`,
  headers: {
    'keep-alive': 'true',
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Auth.getToken();
  let consent = '';
  if (
    window.CookieScript &&
    window.CookieScript.instance &&
    typeof window.CookieScript.instance.currentState === 'function'
  ) {
    consent = window.CookieScript.instance.currentState().categories.join(',');
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      consent,
      originApp: 'Business',
      relevantBusinessChannel: 'B2B',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      // console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      if (error.extensions.code === 'UNAUTHENTICATED') {
        Auth.deauthenticateUser();
        client.resetStore();
        window.location.assign('/login');
      }
    });
  }

  // eslint-disable-next-line
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultCache = {
  data: {
    cart: {
      __typename: 'Cart',
      articles: [],
      total: 0,
    },
  },
};
const cache = new InMemoryCache();
cache.writeData(defaultCache);
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, uploadLink]),
  cache,
  resolvers,
});
client.onResetStore(() => {
  cache.writeData(defaultCache);
});

ReactDOM.render(
  <CookiesProvider>
    <ApolloProvider client={client}>
      <LegacyApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </LegacyApolloProvider>
    </ApolloProvider>
  </CookiesProvider>,
  document.getElementById('root')
);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
