import React from 'react';

export const getAddress = (user, addressType) => {
  return user.addresses.filter((address) => address[addressType]);
};

export const getVerticalAddress = (user, addressType) => {
  return getAddress(user, addressType).map(({ company, street1, zipcode, city }) => (
    <div key={street1} className="address">
      {company || user.company}
      <br />
      {street1}
      <br />
      {zipcode} {city}
    </div>
  ));
};

export const getHorizontalAddress = (user, addressType) => {
  return getAddress(user, addressType).map(({ id, company, street1, zipcode, city }) => (
    <div key={id} className="address">
      {company || user.company}, {street1}, {zipcode} {city}
    </div>
  ));
};
