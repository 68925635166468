import React from 'react';
import PropTypes from 'prop-types';
import { euro } from '../helpers/currency';

const CartFooter = ({ grossTotal, netTotal, taxTotal }) => (
  <div className="cart-footer">
    <div className="cart-hints">
      Soweit nicht anders gekennzeichnet entsprechen die Produkte der Handelsklasse II und die Ware
      stammt aus kontrolliert biologischem Anbau.
    </div>
    <div className="cart-values">
      <div className="values-primary">
        <div className="values-row">
          <div className="values-label">Zwischensumme:</div>
          <div className="values-value">{euro(netTotal)} €</div>
        </div>
        <div className="values-row">
          <div className="values-label">zzgl. MwSt.</div>
          <div className="values-value">{euro(taxTotal)} €</div>
        </div>
      </div>
      <div className="values-gross-total">
        <div className="values-row">
          <div className="values-label">Gesamtsumme:</div>
          <div className="values-value">{euro(grossTotal)} €</div>
        </div>
        <div className="cart-hint">
          Pfand wird in der Gesamtsumme nicht berücksichtigt und erst auf der Rechnung ausgewiesen.
        </div>
      </div>
    </div>
  </div>
);

CartFooter.propTypes = {
  grossTotal: PropTypes.number.isRequired,
  netTotal: PropTypes.number.isRequired,
  taxTotal: PropTypes.number.isRequired,
};

export default CartFooter;
