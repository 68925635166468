import React from 'react';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import moment from 'moment';
import { Button } from 'semantic-ui-react';
import { getCycle } from '../helpers/deliverycycle';
import { euro } from '../helpers/currency';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const ExcelButton = ({ text, articles, region }) => {
  const { deliveryWeek } = getCycle(region);
  const dataSet = [
    {
      columns: [
        {
          title: `Angebot_KW${deliveryWeek}_${region}`,
          width: { wpx: 180 },
          style: { font: { sz: '18', bold: true } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        {
          title: 'Produktname',
          width: { wpx: 140 },
          style: { font: { sz: '14', bold: true } },
        },
        {
          title: 'Herkunft',
          width: { wpx: 150 },
          style: { font: { sz: '14', bold: true } },
        },
        {
          title: 'Besonderheiten',
          width: { wpx: 350 },
          style: { font: { sz: '14', bold: true } },
        },
        {
          title: 'Preis / Einheit',
          width: { wpx: 120 },
          style: { font: { sz: '14', bold: true } },
        },
        {
          title: 'Einheit',
          width: { wpx: 120 },
          style: { font: { sz: '14', bold: true } },
        },
        {
          title: 'Gebinde',
          width: { wpx: 100 },
          style: { font: { sz: '14', bold: true } },
        },
      ],
      data: articles.map((article) => {
        return [
          { value: article.name },
          { value: article.origin },
          { value: article.description },
          { value: `${euro(Number(article.price))} €` },
          { value: article.unitName },
          {
            value: `${Intl.NumberFormat('de-DE').format(article.stockIncrement)} ${
              article.unitName
            }`,
          },
        ];
      }),
    },
  ];

  return (
    <ExcelFile
      element={
        <Button secondary type="button">
          {text}
        </Button>
      }
      filename={`${moment().format('YYMMDD_HH-mm')}_Angebot_KW${deliveryWeek}_${region}`}
    >
      <ExcelSheet dataSet={dataSet} name={`Angebot_KW${deliveryWeek}_${region}`} />
    </ExcelFile>
  );
};

ExcelButton.propTypes = {
  text: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.object),
  region: PropTypes.string,
};

ExcelButton.defaultProps = {
  text: '',
  articles: [],
  region: 'Alle-Regionen',
};

export default ExcelButton;
