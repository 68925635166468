/* eslint react/jsx-one-expression-per-line: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown } from 'semantic-ui-react';
import { Mutation, Query } from 'react-apollo';
import _ from 'lodash';
import { GET_CURRENT_CART, ADD_TO_CART_MUTATION } from '../queries/ProductQueries';
import ArticleFormFooter from './ArticleFormFooter';
import ArticleForm from './ArticleForm';
import { selectArticles } from '../helpers/select-articles';
import SidebarContext from '../contexts/SidebarContext';
import ExcelButton from './ExcelButton';
import { trackAddToCart, trackRemoveFromCart } from '../helpers/tracking';

class ArticleTable extends Component {
  constructor(props) {
    super(props);
    const { categories, articles, regions } = props;

    this.state = {
      allArticles: [...articles],
      articles,
      filteredArticles: [...articles],
    };

    // Generate category dropdown options
    this.categoryOptions = categories.map((category) => {
      return { text: category.name, value: category.id };
    });

    this.originOptions = [
      { text: 'Regional', value: 'regional' },
      { text: 'National', value: 'national' },
      { text: 'International', value: 'international' },
    ];

    [this.region] = regions;
  }

  // Set filter state
  setFilter = (filterValue, filterType) => {
    this.setState(
      {
        [filterType]: filterValue,
      },
      this.updateResults
    );
  };

  // Update results based on filter state
  updateResults = () => {
    const { allArticles, category, origin } = this.state;
    const search = this.state.search ? this.state.search.toLowerCase() : '';

    const filteredArticles = allArticles.filter((article) => {
      if (origin === 'international') {
        return (
          !article.origin.toLowerCase().includes('regional') &&
          !article.origin.toLowerCase().includes('deutschland') &&
          article.articleCategoryId.includes(category || '') &&
          article.name.toLowerCase().includes(search)
        );
      } else if (origin === 'national') {
        return (
          (article.origin.toLowerCase().includes('regional') ||
            article.origin.toLowerCase().includes('deutschland')) &&
          article.articleCategoryId.includes(category || '') &&
          article.name.toLowerCase().includes(search)
        );
      }

      return (
        article.origin.toLowerCase().includes(origin || '') &&
        article.articleCategoryId.includes(category || '') &&
        article.name.toLowerCase().includes(search)
      );
    });

    this.setState({ filteredArticles });
  };

  render() {
    const { articles, category, origin, allArticles, filteredArticles } = this.state;

    return (
      <>
        <div className="shop-table-container">
          <div className="filters">
            <div className="filter-item search">
              <div className="filter-label">Suche:</div>
              <Input
                placeholder="Wochenangebot durchsuchen"
                onChange={(e, { value }) => this.setFilter(value.trim(), 'search')}
              />
            </div>
            <div className="filter-wrapper">
              <div className="filter-dropdown">
                <span>Filter</span>
              </div>
              <div className="filter-content">
                <div className="filter-item filter">
                  <div className="filter-label">Herkunft:</div>
                  <Dropdown
                    onChange={(e, { value }) => this.setFilter(value, 'origin')}
                    options={this.originOptions}
                    placeholder="überregionales Angebot"
                    clearable
                    selection
                    value={origin}
                  />
                </div>
                <div className="filter-item filter">
                  <div className="filter-label">Art der Produkte:</div>
                  <Dropdown
                    onChange={(e, { value }) => this.setFilter(value, 'category')}
                    options={this.categoryOptions}
                    placeholder="Obst & Gemüse anzeigen"
                    clearable
                    selection
                    value={category}
                  />
                </div>
                <ExcelButton
                  text="Angebot herunterladen"
                  articles={allArticles}
                  region={this.region}
                />
              </div>
            </div>
          </div>
          <div className="results">{filteredArticles.length} Ergebnisse:</div>
        </div>
        <Query query={GET_CURRENT_CART}>
          {({ data, loading }) => {
            if (loading) return null;
            const { total, articles: cartArticles } = data.cart;

            return (
              <ArticleForm
                cartArticles={cartArticles}
                articles={articles}
                filteredArticles={filteredArticles}
                render={({ values, isValid, validate, onSuccess, hasChanges }) => {
                  return (
                    <Mutation mutation={ADD_TO_CART_MUTATION}>
                      {(addToCart) => (
                        <SidebarContext.Consumer>
                          {({ showSidebar }) => (
                            <ArticleFormFooter
                              total={total}
                              articles={cartArticles}
                              isValid={isValid}
                              hasChanges={hasChanges}
                              onShowCart={showSidebar}
                              onAddToCart={async () => {
                                if (!_.size(values)) return;
                                const selectedArticles = selectArticles(allArticles, values);
                                const isValidAfter = await validate();
                                if (isValidAfter) {
                                  addToCart({ variables: { articles: selectedArticles } });
                                  selectedArticles.forEach((selectedArticle) => {
                                    const existingCartArticle = cartArticles.find(
                                      (cartArticle) =>
                                        cartArticle.articleId === selectedArticle.articleId
                                    );
                                    const productListPosition =
                                      filteredArticles.findIndex(
                                        (filteredArticle) =>
                                          filteredArticle.articleId === selectedArticle.articleId
                                      ) + 1;

                                    if (existingCartArticle) {
                                      if (selectedArticle.amount > existingCartArticle.amount) {
                                        trackAddToCart(
                                          selectedArticle,
                                          selectedArticle.amount - existingCartArticle.amount,
                                          productListPosition
                                        );
                                      } else if (
                                        selectedArticle.amount < existingCartArticle.amount
                                      ) {
                                        trackRemoveFromCart(
                                          selectedArticle,
                                          existingCartArticle.amount - selectedArticle.amount,
                                          productListPosition
                                        );
                                      }
                                    } else {
                                      trackAddToCart(
                                        selectedArticle,
                                        selectedArticle.amount,
                                        productListPosition
                                      );
                                    }
                                  });
                                  onSuccess();
                                }
                              }}
                            />
                          )}
                        </SidebarContext.Consumer>
                      )}
                    </Mutation>
                  );
                }}
              />
            );
          }}
        </Query>
      </>
    );
  }
}

ArticleTable.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  regions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArticleTable;
