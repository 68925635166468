/* eslint func-names: 0 */
import React, { Component } from 'react';
import { Card, Message, Header, Grid } from 'semantic-ui-react';
import { Button, Form, Input } from 'formik-semantic-ui';
import { Redirect, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { UPDATE_USER } from '../queries/UserQueries';
import removeGQLError from '../helpers/remove-gql-error';
import { client } from '../index';

Yup.addMethod(Yup.object, 'poBoxTest', function (fieldName) {
  let otherField1 = '';
  let otherField2 = '';
  switch (fieldName) {
    case 'poBoxNumber':
      otherField1 = 'poBoxZip';
      otherField2 = 'poBoxCity';
      break;
    case 'poBoxZip':
      otherField1 = 'poBoxNumber';
      otherField2 = 'poBoxCity';
      break;
    case 'poBoxCity':
      otherField1 = 'poBoxNumber';
      otherField2 = 'poBoxZip';
      break;
    default:
      break;
  }

  return this.test('test1', (value) => {
    if (value[fieldName] && !value[otherField1]) {
      return this.createError({
        path: `${otherField1}`,
        message: 'Erforderlich bei Postfachnutzung',
      });
    }
    return true;
  }).test('test2', (value) => {
    if (value[fieldName] && !value[otherField2]) {
      return this.createError({
        path: `${otherField2}`,
        message: 'Erforderlich bei Postfachnutzung',
      });
    }
    return true;
  });
});

class BillingFormContainer extends Component {
  billingSchema = Yup.object({
    companyName: Yup.string().required('Erforderlich'),
    companyStreet: Yup.string().required('Erforderlich'),
    zipCode: Yup.string().required('Erforderlich'),
    addressAddition: Yup.string(),
    companyCity: Yup.string().required('Erforderlich'),
    poBoxNumber: Yup.number(),
    poBoxZip: Yup.number(),
    poBoxCity: Yup.string(),
  })
    .poBoxTest('poBoxNumber')
    .poBoxTest('poBoxZip')
    .poBoxTest('poBoxCity');

  constructor() {
    super();
    this.state = {
      errorMessage: null,
      redirectCheckout: false,
    };
  }

  handleSubmit = async (values, formikApi) => {
    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: { ...values },
      });
      this.setState({ redirectCheckout: true });
    } catch (e) {
      this.setState({ errorMessage: removeGQLError(e.message) });
    }
    formikApi.setSubmitting(false);
  };

  render() {
    const { errorMessage, redirectCheckout } = this.state;
    const { handleSubmit, billingSchema } = this;

    if (redirectCheckout) {
      return <Redirect to="/checkout" />;
    }

    return (
      <Card className="card-auth card-billingform">
        <Card.Content>
          <div className="view-header">
            <Header as="h1" className="header--is-handwritten">
              Bestellung abschließen:
            </Header>
            <Link to="./shop">zurück zum Shop</Link>
          </div>
          {errorMessage && <Message negative header="Hoppla!" content={errorMessage} />}
          <Form
            initialValues={{
              companyName: '',
              companyStreet: '',
              zipCode: '',
              addressAddition: '',
              companyCity: '',
              poBoxNumber: '',
              poBoxZip: '',
              poBoxCity: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={billingSchema}
            validateOnBlur={false}
          >
            <Grid columns={2}>
              <Grid.Column>
                <Header as="h3">Rechnungsadresse einrichten</Header>
                <Input label="Firmenname" name="companyName" />
                <Input label="Strasse & Hausnummer" name="companyStreet" />
                <Input
                  label="Adresszusatz"
                  name="addressAddition"
                  fieldProps={{ className: 'is-optional' }}
                />
                <Input label="PLZ" name="zipCode" />
                <Input label="Stadt" name="companyCity" />
              </Grid.Column>
              <Grid.Column>
                <Header as="h3">Postfach</Header>
                <Input
                  label="Postfachnummer"
                  name="poBoxNumber"
                  fieldProps={{ className: 'is-optional' }}
                />
                <Input
                  label="Postfach PLZ"
                  name="poBoxZip"
                  fieldProps={{ className: 'is-optional' }}
                />
                <Input
                  label="Postfach Stadt"
                  name="poBoxCity"
                  fieldProps={{ className: 'is-optional' }}
                />
                <br />
                <Button.Submit> Speichern </Button.Submit>
              </Grid.Column>
            </Grid>
          </Form>
        </Card.Content>
      </Card>
    );
  }
}

export default BillingFormContainer;
