import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import cartIcon from '../assets/cart-icon-white.svg';
import infoIcon from '../assets/information-icon-brown.svg';
import { euro } from '../helpers/currency';
import { calcTotalWeight } from '../helpers/totals';
import { getMinimumWeight } from '../helpers/minimum-weight';

const ArticleFormFooter = ({ onAddToCart, onShowCart, total, articles, isValid, hasChanges }) => {
  const totalWeight = calcTotalWeight(articles);
  const minWeight = getMinimumWeight();

  const cartIsEmpty = articles.length === 0;
  const cartUpdateable = !cartIsEmpty && hasChanges;
  const cartShowable = !cartIsEmpty && !hasChanges;

  let buttonText = (
    <>
      Zum Warenkorb
      <br />
      hinzufügen
    </>
  );
  let footerID = 'hidefooter';

  if (cartUpdateable) {
    buttonText = (
      <>
        Warenkorb
        <br /> aktualisieren
      </>
    );
    footerID = 'showfooter';
  } else if (cartShowable) {
    buttonText = (
      <>
        Warenkorb
        <br /> anzeigen
      </>
    );
    footerID = 'showfooter';
  } else if (hasChanges) {
    footerID = 'showfooter';
  }

  const button = (
    <Button
      secondary={cartIsEmpty}
      primary={cartShowable}
      basic={cartUpdateable}
      onClick={cartShowable ? onShowCart : onAddToCart}
      type="button"
      className="ui two-line-btn"
    >
      {buttonText}
    </Button>
  );

  const infoText = (
    <span className="smalltext boldtext">
      <img src={infoIcon} alt="" /> geänderte Bestellmenge
    </span>
  );

  const popup = <Popup trigger={button} content="Bitte korrigieren Sie die Fehler" />;

  return (
    <div className="article-form-footer" id={footerID}>
      <div className="cart-details">
        <Button className="cart-icon" type="button" onClick={onShowCart}>
          <img src={cartIcon} alt="" />
          <span className="smalltext">Warenkorb anzeigen</span>
        </Button>
        <div className="detail-items">
          <div className="article-count">
            {articles.length} Artikel <span className="no-mobile">im Warenkorb &nbsp;</span>
            {minWeight > 0 && (
              <div className="mbw greytext boldtext no-mobile">
                (Mindestbestellmenge {totalWeight}/{minWeight} kg)
              </div>
            )}
          </div>
          <div className="cart-total">
            <span className="no-mobile">Zwischensumme (netto):</span> {euro(total)} €
          </div>
          {minWeight > 0 && (
            <div className="mbw-for-mobile greytext boldtext">
              (Mbm. {totalWeight}/{minWeight} kg)
            </div>
          )}
        </div>
      </div>
      <div className="cart-actions">
        {hasChanges && infoText}
        {!isValid && popup}
        {isValid && button}
      </div>
    </div>
  );
};

ArticleFormFooter.propTypes = {
  onAddToCart: PropTypes.func.isRequired,
  onShowCart: PropTypes.func.isRequired,
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
};

export default ArticleFormFooter;
