import gql from 'graphql-tag';
import { userFragment } from './UserQueries';

export const SETUP_QUERY = gql`
  {
    currentUser {
      ...userValues
    }
  }
  ${userFragment}
`;
