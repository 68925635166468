import gql from 'graphql-tag';

export const userFragment = gql`
  fragment userValues on Customer {
    id
    customerId
    active
    customerNumber
    recentOrderDate
    recentDeliveryDate
    recentOrderId
    recentOrderNumber
    tgcAccepted
    dataPrivacyAccepted
    firstName
    lastName
    email
    phoneNumber
    addresses {
      id
      company
      city
      street1
      invoiceAddress
      deliveryAddress
      zipcode
    }
    company
    regions
    salesChannel
    shopSelections
    deliveryDay
    deliveryWindowStart
    deliveryWindowEnd
  }
`;

export const GET_USER = gql`
  {
    currentUser {
      ...userValues
    }
  }
  ${userFragment}
`;

export const GET_GREETING_DATA = gql`
  {
    currentUser {
      ...userValues
    }
    reviews {
      id
      salesOrderId
    }
  }
  ${userFragment}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $company: String
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $customAttributes: [customAttribute]
    $tgcAccepted: String
    $dataPrivacyAccepted: String
    $companyName: String
    $companyStreet: String
    $addressAddition: String
    $zipCode: String
    $companyCity: String
    $poBoxNumber: String
    $poBoxZip: String
    $poBoxCity: String
  ) {
    updateUser(
      userVars: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        tgcAccepted: $tgcAccepted
        dataPrivacyAccepted: $dataPrivacyAccepted
      }
      customerVars: {
        company: $company
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        contactVars: {
          company: $company
          firstName: $firstName
          lastName: $lastName
          email: $email
          phone: $phone
        }
        customAttributes: $customAttributes
        billingVars: {
          company: $companyName
          city: $companyCity
          street1: $companyStreet
          addressAddition: $addressAddition
          zipCode: $zipCode
          poBoxNumber: $poBoxNumber
          poBoxZip: $poBoxZip
          poBoxCity: $poBoxCity
        }
      }
    ) {
      ...userValues
      ... on Customer {
        addresses {
          id
          company
          city
          street1
          invoiceAddress
          deliveryAddress
          zipcode
        }
      }
    }
  }
  ${userFragment}
`;

export const UPDATE_COOKIE_CONSENT = gql`
  mutation UpdateCookieConsent {
    updateCookieConsent
  }
`;

export const FOOD_WASTE_REPORTS = gql`
  query FoodWasteReports {
    foodWasteReports {
      url
      lastModifiedDate
    }
  }
`;
