import * as Yup from 'yup';

export const accountDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('Erforderlich'),
  firstName: Yup.string().required('Erforderlich'),
  lastName: Yup.string().required('Erforderlich'),
  email: Yup.string()
    .required('Erforderlich')
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Ungültige E-Mail'
    ),
  phone: Yup.string().required('Erforderlich'),
  deliveryDay: Yup.string().required('Erforderlich'),
  deliveryWindowStart: Yup.string().required('Erforderlich'),
  deliveryWindowEnd: Yup.string().required('Erforderlich'),
});
