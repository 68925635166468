import { client } from '../index';
import {
  GET_CURRENT_CART,
  ADD_TO_CART_MUTATION,
  COMMIT_CART_MUTATION,
} from '../queries/ProductQueries';

class CartStore {
  static setStoredCart = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
  };

  static getStoredCart = () => {
    return JSON.parse(localStorage.getItem('cart'));
  };

  static hydrateCache = () => {
    const storedCart = CartStore.getStoredCart();
    if (storedCart) {
      client.mutate({
        mutation: ADD_TO_CART_MUTATION,
        variables: {
          articles: storedCart.articles,
        },
      });
    }
  };

  static saveCacheToCartStore = () => {
    const { cart } = client.readQuery({ query: GET_CURRENT_CART });
    CartStore.setStoredCart(cart);
  };

  static clearCart = () => {
    client.mutate({
      mutation: COMMIT_CART_MUTATION,
      variables: {
        articles: [],
        total: 0,
      },
    });
    localStorage.setItem(
      'cart',
      JSON.stringify({
        __typename: 'Cart',
        articles: [],
        total: 0,
      })
    );
  };
}

export default CartStore;
