import React from 'react';
import PropTypes from 'prop-types';

const AuthContainer = (props) => <div className="auth-container">{props.children}</div>;

AuthContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContainer;
