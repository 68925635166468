export const digits = [
  { text: '0', value: 0 },
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
  { text: '13', value: 13 },
  { text: '14', value: 14 },
  { text: '15', value: 15 },
  { text: '16', value: 16 },
];

export const monToTues = [
  { text: 'Montag', value: 'Montag' },
  { text: 'Dienstag', value: 'Dienstag' },
];

export const weekdays = [
  { text: 'Montag', value: 'Montag' },
  { text: 'Dienstag', value: 'Dienstag' },
  { text: 'Mittwoch', value: 'Mittwoch' },
  { text: 'Donnerstag', value: 'Donnerstag' },
  { text: 'Freitag', value: 'Freitag' },
];
