import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Success = ({ header, children, backTo, headerWidget }) => (
  <div className="generic-message success">
    {headerWidget || ''}
    <Header as="h1">{header}</Header>
    <div className="greytext smalltext">{children}</div>
    <Link to={`/${backTo}`} className="backbutton">
      <Icon name="arrow left" />
      zurück {backTo === '' ? '' : `zum ${backTo}`}
    </Link>
  </div>
);

Success.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  backTo: PropTypes.string,
  headerWidget: PropTypes.node,
};

Success.defaultProps = {
  backTo: '',
  headerWidget: '',
};

export default Success;
