import gql from 'graphql-tag';

export const VERIFY_TOKEN_QUERY = gql`
  query VerifyToken($token: String!) {
    verifyToken(token: $token) {
      id
      email
      contacts {
        salutation
        firstName
        lastName
      }
      shopSelections
      marketingEmailsWanted
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation Register(
    $salutation: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $customerId: String!
    $marketingEmailsWanted: Boolean!
    $tgcAccepted: String!
    $dataPrivacyAccepted: String!
    $shopSelection: String!
    $feldbotschaftPrivacy: String
    $feldbotschaftEntryCode: String
    $originApp: String
  ) {
    directCustomerRegister(
      salutation: $salutation
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      customerId: $customerId
      marketingEmailsWanted: $marketingEmailsWanted
      tgcAccepted: $tgcAccepted
      dataPrivacyAccepted: $dataPrivacyAccepted
      shopSelection: $shopSelection
      feldbotschaftPrivacy: $feldbotschaftPrivacy
      feldbotschaftEntryCode: $feldbotschaftEntryCode
      originApp: $originApp
    ) {
      token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email, type: "customer") {
      success
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      success
    }
  }
`;
