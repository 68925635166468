import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader, Message } from 'semantic-ui-react';
import moment from 'moment';
import { SHOP_DATA_QUERY } from '../queries/ProductQueries';
import ArticleTable from './ArticleTable';
import { GET_USER } from '../queries/UserQueries';
import config from '../config';
import { trackProductListViewed } from '../helpers/tracking';

const ShopContent = () => {
  const shopDataQuery = useQuery(SHOP_DATA_QUERY);
  const userQuery = useQuery(GET_USER);

  useEffect(() => {
    if (shopDataQuery.loading || shopDataQuery.error) return;
    trackProductListViewed(
      shopDataQuery.data.businessArticles,
      userQuery.data.currentUser.regions[0]
    );
    // eslint-disable-next-line
  }, [shopDataQuery]);

  if (shopDataQuery.loading) return <Loader active inline="centered" />;
  if (shopDataQuery.error) return `Error!: ${shopDataQuery.error}`;
  if (userQuery.loading) return <Loader active inline="centered" />;
  if (userQuery.error) return `Error!: ${userQuery.error}`;

  if (shopDataQuery.data.businessArticles.length === 0) {
    return (
      <Message compact warning style={{ padding: '1.75em 2em' }}>
        Liebe Fans des krummes Obst und Gemüses,
        <br />
        In Ihrer Region bieten wir derzeit keine Produkte an. Sobald wieder krumme Schätze im
        Angebot stehen, werden Sie von uns per E-Mail benachrichtigt. Falls Sie Fragen haben, nehmen
        Sie gerne Kontakt zu uns auf:{' '}
        <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
      </Message>
    );
  }

  return (
    <>
      {/* START: Holiday timing logic */}
      {moment().isBetween(
        moment('2023-09-20').startOf('day'),
        moment('2023-10-03').endOf('day')
      ) && (
        <Message
          visible
          warning
          icon="warning sign"
          content={
            <>
              Für Kunden mit dem Liefertag Dienstag, den 03.10.2023, findet die Anlieferung aufgrund
              des Feiertags einen Tag später am Mittwoch, den 04.10.2023, statt.
            </>
          }
        />
      )}
      {/* END: Holiday timing logic */}
      <ArticleTable
        articles={shopDataQuery.data.businessArticles}
        categories={shopDataQuery.data.articleCategories.filter((category) =>
          config.activeCategories.includes(category.name.toLowerCase())
        )}
        regions={userQuery.data.currentUser.regions}
      />
      <div className="cart-footer">
        <div className="cart-hints">
          <p>
            Soweit nicht anders gekennzeichnet entsprechen die Produkte der Handelsklasse II und die
            Ware stammt aus kontrolliert biologischem Anbau.
          </p>
          <p>
            * Bitte beachten Sie, dass unsere Pilze naturbelassen und frei von Konservierungsstoffen
            sind. Das bedeutet, dass sie bei längerer Lagerung an Gewicht verlieren können, da sie
            aufgrund des natürlichen Trocknungsprozesses Wasser verlieren.
          </p>
        </div>
        <div className="organic">
          <div className="bio__bio-logo" alt="Bio nach EG-Öko-Verordnung" />
          <div className="bio__eu-logo" alt="EU Bio Logo" />
        </div>
      </div>
    </>
  );
};

export default ShopContent;
