/* eslint react/no-array-index-key: 0 */
import React from 'react';
import { Card, Header, Table, Button, Icon, Loader, Message } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import config from '../config';
import { euro } from '../helpers/currency';
import { GET_ORDERS } from '../queries/OrderQueries';
import pdfIcon from '../assets/pdf-icon.svg';
import showOrderIcon from '../assets/show-order-icon.svg';
import editOrderIcon from '../assets/edit-order-icon.svg';
import { calcTotalPrice } from '../helpers/totals';
import removeGqlError from '../helpers/remove-gql-error';

const OrderHistory = withRouter(({ history }) => {
  const { data, loading, error } = useQuery(GET_ORDERS, {
    variables: { shopSelection: 'Business' },
  });

  const createTable = (orders) => {
    // create empty table (array of rows)
    const rows = [];

    orders.forEach(
      (
        { id: orderId, orderDate, orderNumber, orderItems, plannedShippingDate, invoiced, status },
        i
      ) => {
        const orderTotal = calcTotalPrice(orderItems).grossTotal;
        orderDate = moment(Number(orderDate));
        plannedShippingDate = moment(Number(plannedShippingDate));
        const editOrderCutoffDate = orderDate
          .clone()
          .set({ day: 3, hour: 12, minute: 0, second: 0 });

        const cancelled = status === 'CANCELLED';
        const isEditable = moment() < editOrderCutoffDate && !cancelled;

        let statusIcon = <span className="processing">Auftrag erstellt</span>;
        if (invoiced) {
          statusIcon = <span className="completed">Abgeschlossen</span>;
        } else if (cancelled) {
          statusIcon = <span className="canceled">Storniert</span>;
        }

        rows.push(
          <Table.Row key={`${i}row`}>
            <Table.Cell className="ordernumber" key={`${i}${orderNumber}`}>
              {orderNumber}
            </Table.Cell>
            <Table.Cell className="orderdate" key={`${i}${orderDate}`}>
              {orderDate.format('DD.MM.YY')}
            </Table.Cell>
            <Table.Cell className="plannedshippingdate" key={`${plannedShippingDate}${i}`}>
              {plannedShippingDate.format('DD.MM.YY')}
            </Table.Cell>
            <Table.Cell className="status" key={`${i}yeet`}>
              {statusIcon}
            </Table.Cell>
            <Table.Cell className="totalprice" key={`${i}skeet`}>
              {euro(orderTotal)} €
            </Table.Cell>
            <Table.Cell className="orderconfirmation" key={`${i}viewPdf`}>
              <Button
                className="show-pdf icon"
                href={`${config.api.baseURL}/order-confirmation/${orderId}`}
                target="_blank"
                rel="noopener noreferrer"
                type="button"
              >
                <img src={pdfIcon} alt="" />
                <span>Auftrags-bestätigung</span>
              </Button>
            </Table.Cell>
            <Table.Cell className="vieweditorder" key={`${i}viewOrder`}>
              <Button
                className="show-order icon"
                type="button"
                // eslint-disable-next-line
                onClick={() => history.push(`/order-details/${orderId}`)}
              >
                <img src={isEditable ? editOrderIcon : showOrderIcon} alt="" />
                <span>{isEditable ? 'Bestellung bearbeiten' : 'Bestellung anzeigen'}</span>
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      }
    );
    return rows;
  };

  if (loading) return <Loader active inline="centered" />;
  if (error) {
    return <Message negative header="Hoppla!" content={removeGqlError(error.message)} />;
  }
  return (
    <Card className="orderhistory">
      <Card.Content>
        {data.getOrders ? (
          <>
            <Link to="/shop">
              <Icon name="arrow left" />
              zurück zum Shop
            </Link>
            <div className="view-header">
              <Header as="h1" className="header--is-handwritten">
                Meine Bestellungen:
              </Header>
            </div>
            <div className="tablewrapper">
              <Table>
                <Table.Header className="table-header">
                  <Table.Row>
                    <Table.HeaderCell className="ordernumber">Auftrags-Nr.</Table.HeaderCell>
                    <Table.HeaderCell className="orderdate">Bestelldatum</Table.HeaderCell>
                    <Table.HeaderCell className="plannedshippingdate">
                      geplantes Lieferdatum
                    </Table.HeaderCell>
                    <Table.HeaderCell className="status">Auftragstatus</Table.HeaderCell>
                    <Table.HeaderCell className="totalprice">Summe (netto)</Table.HeaderCell>
                    <Table.HeaderCell className="orderconfirmation" />
                    <Table.HeaderCell className="vieweditorder" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>{createTable(data.getOrders)}</Table.Body>
              </Table>
            </div>
            <div className="footer">
              <span>
                Benötigen Sie Hilfe oder haben Sie Fragen zu Ihrer Bestellung?
                <br />
                Besuchen Sie unsere <Link to="/contact">Hilfeseite</Link> oder schreiben Sie uns an{' '}
                <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
              </span>
            </div>
          </>
        ) : (
          <>
            <Header as="h1">Hoppla!</Header>
            <p>
              Sie haben bisher keine Bestellung bei uns aufgegeben. <br />
              Wir freuen uns bald mit Ihnen Obst und Gemüse retten zu können!
            </p>
            <Link to="/shop">Zurück zum Shop</Link>
          </>
        )}
      </Card.Content>
    </Card>
  );
});

export default OrderHistory;
