import moment from 'moment';
import config from '../config';
import 'moment/locale/de';

export const getCycle = (region) => {
  const now = moment().locale('de');

  const openingDay = config.openingDay.default;
  const openingTime = config.openingTime.default;
  const closingDay = config.closingDay[region] || config.closingDay.default;
  const closingTime = config.closingTime[region] || config.closingTime.default;

  const openingMoment = now.clone().weekday(openingDay).hour(openingTime).startOf('hour');
  const closingMoment = now.clone().weekday(closingDay).hour(closingTime).startOf('hour');
  const deliveryWeek = now.isBefore(openingMoment)
    ? now.isoWeek()
    : now.clone().add(1, 'week').isoWeek();

  // START: Holiday timing logic
  if (closingMoment.year() === 2023 && closingMoment.isoWeek() === 21) {
    if (region === 'NRW' || region === 'FFM') {
      closingMoment.hour(7);
    } else if (region === 'BER' || region === 'MUC' || region === 'HH') {
      closingMoment.hour(8);
    }
  }
  // END: Holiday timing logic

  const onCycle = now.isSameOrAfter(openingMoment) && now.isBefore(closingMoment);

  const nextOpeningMoment = now.isSameOrBefore(openingMoment)
    ? openingMoment
    : now.clone().weekday(openingDay).hour(openingTime).startOf('hour').add(1, 'week');
  const nextClosingMoment = now.isSameOrBefore(openingMoment)
    ? closingMoment
    : now.clone().weekday(closingDay).hour(closingTime).startOf('hour').add(1, 'week');

  return {
    onCycle,
    deliveryWeek,
    openingMoment,
    closingMoment,
    nextOpeningMoment,
    nextClosingMoment,
  };
};
