import React, { useState } from 'react';
import { Card, Header, Grid, Message } from 'semantic-ui-react';
import { Button, Form, Input, Dropdown, Radio } from 'formik-semantic-ui';
import { Query } from 'react-apollo';
import { digits, weekdays } from '../helpers/constants';
import { GET_USER, UPDATE_USER } from '../queries/UserQueries';
import { getVerticalAddress } from '../helpers/user-formatting';
import { client } from '../index';
import { accountDetailsSchema } from '../schemas/yup/accountDetails';
import { SETUP_QUERY } from '../queries/SetupQueries';

const AccountDetails = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const handleSubmit = async (values, formikApi) => {
    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          ...values,
          customAttributes: [
            {
              name: 'deliveryDay',
              value: String(values.deliveryDay),
            },
            {
              name: 'deliveryWindowStart',
              value: String(values.deliveryWindowStart),
            },
            {
              name: 'deliveryWindowEnd',
              value: String(values.deliveryWindowEnd),
            },
          ],
        },
        refetchQueries: [{ query: GET_USER }, { query: SETUP_QUERY }],
      });
      setIsFailed(false);
      setIsSuccessful(true);
    } catch (e) {
      setIsSuccessful(false);
      setIsFailed(true);
      throw new Error(e);
    }
    formikApi.setSubmitting(false);
  };

  return (
    <Query query={GET_USER}>
      {({ data: { currentUser } }) => {
        if (!currentUser) return null;
        return (
          <>
            <Card className="card-auth card-accountdetails">
              <Card.Content>
                <div className="view-header">
                  <Header as="h1" className="header--is-handwritten">
                    Einstellungen:
                  </Header>
                </div>
                {isFailed && (
                  <Message
                    negative
                    header="Fehler:"
                    content="Leider ist etwas schief gelaufen. Bitte versuchen Sie es noch einmal."
                  />
                )}
                {isSuccessful && (
                  <Message
                    positive
                    header="Änderungen gespeichert!"
                    content="Wir haben ihre geänderten Daten erfolgreich übernommen. Vielen Dank."
                  />
                )}
                <Form
                  initialValues={{
                    ...currentUser,
                    companyName: currentUser.company,
                    phone: currentUser.phoneNumber,
                  }}
                  // eslint-disable-next-line
                  onSubmit={handleSubmit}
                  validationSchema={accountDetailsSchema}
                >
                  {({ values, setFieldValue }) => (
                    <>
                      <Grid columns={2}>
                        <Grid.Column>
                          <Header as="h3">Angaben zu Ihrem Betrieb:</Header>
                          <Input
                            label="Firmenname"
                            name="companyName"
                            inputProps={{ disabled: true }}
                          />
                          <Header as="h3">Kontaktinformationen:</Header>
                          <Input label="Vorname" name="firstName" inputProps={{ disabled: true }} />
                          <Input label="Nachname" name="lastName" inputProps={{ disabled: true }} />
                          <Input label="E-Mail" name="email" inputProps={{ disabled: true }} />
                          <Input
                            label="Telefonnummer"
                            name="phone"
                            inputProps={{ disabled: true }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h3">Lieferdaten:</Header>
                          <div className="field label">Lieferadresse</div>
                          <Radio
                            name="deliveryAddress"
                            label={getVerticalAddress(currentUser, 'deliveryAddress')}
                            inputProps={{ disabled: true }}
                          />
                          <p className="smalltext">
                            Neue Lieferadresse hinzufügen?{' '}
                            <a href="mailto:kundenservice@querfeld.bio">
                              Bitte kontaktieren Sie uns
                            </a>
                          </p>
                          {!currentUser.shopSelections.includes('Feldbotschaft') && (
                            <div className="delivery-window">
                              <div htmlFor="field_dropdown_deliveryDay" className="textwrapper">
                                <span>Präferierte Lieferung am:</span>
                                <Dropdown
                                  inputProps={{ disabled: true }}
                                  name="deliveryDay"
                                  options={weekdays}
                                />
                                <span>von</span>
                                <Dropdown
                                  inputProps={{
                                    disabled: true,
                                    className: 'deliveryWindow',
                                    onChange: (e, v) => {
                                      if (values.deliveryWindowEnd < v.value + 3) {
                                        setFieldValue('deliveryWindowEnd', v.value + 3);
                                      }
                                    },
                                  }}
                                  name="deliveryWindowStart"
                                  options={digits.slice(5, 11)}
                                />
                                <span>bis</span>
                                <Dropdown
                                  disabled
                                  inputProps={{
                                    disabled: true,
                                    className: 'deliveryWindow',
                                  }}
                                  name="deliveryWindowEnd"
                                  options={digits.slice(8, 14)}
                                />
                                <span>Uhr</span>
                                <p className="hint information">
                                  Wir werden Ihre gewünschte Lieferzeit bei der Planung
                                  berücksichtigen,
                                  <br />
                                  können die Lieferzeit aber nicht garantieren.
                                </p>
                              </div>
                              <br />
                            </div>
                          )}
                          <Header as="h3">Zahlungsart:</Header>
                          <p className="smalltext">
                            Legen Sie Ihr gewünschtes Zahlungsmittel fest.
                          </p>
                          <div className="field label">Auf Rechnung</div>
                          <Radio
                            name="invoiceAddress"
                            label={getVerticalAddress(currentUser, 'invoiceAddress')}
                            defaultChecked
                            inputProps={{ disabled: true }}
                          />
                          <p className="smalltext">
                            Rechnungsadresse hinzufügen oder ändern?{' '}
                            <a href="mailto:kundenservice@querfeld.bio">
                              Bitte kontaktieren Sie uns
                            </a>
                          </p>
                        </Grid.Column>
                      </Grid>
                      <div className="formfooter buttonrow">
                        <Button.Reset secondary> Abbrechen </Button.Reset>
                        <Button.Submit> Speichern </Button.Submit>
                      </div>
                    </>
                  )}
                </Form>
              </Card.Content>
            </Card>
          </>
        );
      }}
    </Query>
  );
};

export default AccountDetails;
