import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon, Accordion, Loader, Message } from 'semantic-ui-react';
import { Form, Dropdown, Radio } from 'formik-semantic-ui';
import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER } from '../queries/UserQueries';
import { getVerticalAddress } from '../helpers/user-formatting';
import { digits, weekdays } from '../helpers/constants';
import removeGqlError from '../helpers/remove-gql-error';

const DeliveryInfoForm = (props) => {
  const { data, loading, error } = useQuery(GET_USER);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { setSubmitButtonRef, onSubmit, deliveryWindow = {} } = props;
  const isEditable = false;

  if (loading) return <Loader active inline="centered" />;
  if (error) return <Message negative header="Hoppla!" content={removeGqlError(error.message)} />;
  return (
    <Query query={GET_USER}>
      {({ data: { currentUser } }) => {
        if (!currentUser) return null;
        return (
          <Form
            initialValues={{
              deliveryWindowStart: Number(deliveryWindow.deliveryWindowStart) || 4,
              deliveryWindowEnd: Number(deliveryWindow.deliveryWindowEnd) || 16,
              deliveryDay: deliveryWindow.deliveryDay || 'Montag',
            }}
            onSubmit={onSubmit}
            className="accordion"
          >
            <Accordion>
              <Accordion.Title
                active={accordionOpen}
                onClick={() => setAccordionOpen(!accordionOpen)}
              >
                <Icon name="dropdown" />
                Liefer- und Zahlungsdaten anzeigen
              </Accordion.Title>
              <Accordion.Content active={accordionOpen}>
                <div className="delivery-settings">
                  <div className="delivery-address">
                    <Header as="h3">Lieferdaten:</Header>
                    <div className="field label">Lieferadresse:</div>
                    <Radio
                      name="deliveryAddress"
                      label={getVerticalAddress(currentUser, 'deliveryAddress')}
                      defaultChecked
                    />
                    <p className="newaddress smalltext">
                      Neue Lieferadresse hinzufügen?{' '}
                      <a href="mailto:kundenservice@querfeld.bio">Bitte kontaktieren uns</a>
                    </p>
                  </div>
                  {!data.currentUser.shopSelections.includes('Feldbotschaft') && (
                    <div className="delivery-window">
                      <div htmlFor="field_dropdown_deliveryDay" className="textwrapper">
                        <span>Präferierte Lieferung am:</span>
                        <Dropdown
                          inputProps={{
                            disabled: !isEditable,
                          }}
                          name="deliveryDay"
                          options={weekdays}
                        />
                        <span>von</span>
                        <Dropdown
                          inputProps={{
                            className: 'deliveryWindow',
                            disabled: !isEditable,
                          }}
                          name="deliveryWindowStart"
                          options={digits}
                        />
                        <span>bis</span>
                        <Dropdown
                          inputProps={{
                            className: 'deliveryWindow',
                            disabled: !isEditable,
                          }}
                          name="deliveryWindowEnd"
                          options={digits}
                        />
                        <span>Uhr</span>
                        <p className="hint information">
                          Wir werden Ihre gewünschte Lieferzeit bei der Planung berücksichtigen,
                          <br />
                          können die Lieferzeit aber nicht garantieren.
                        </p>
                      </div>
                      <br />
                    </div>
                  )}
                </div>
                <div className="invoice-settings">
                  <Header as="h3">Zahlungsdaten:</Header>
                  <div className="field label">Auf Rechnung:</div>
                  <Radio
                    name="invoiceAddress"
                    label={getVerticalAddress(currentUser, 'invoiceAddress')}
                    defaultChecked
                  />
                  <p className="newaddress smalltext">
                    Rechnungsadresse hinzufügen oder ändern?{' '}
                    <a href="mailto:kundenservice@querfeld.bio">Bitte kontaktieren uns</a>
                  </p>
                </div>
              </Accordion.Content>
            </Accordion>

            {/* Hidden button connected to submit button in parent component */}
            <button hidden type="submit" ref={(el) => setSubmitButtonRef(el)} />
          </Form>
        );
      }}
    </Query>
  );
};

DeliveryInfoForm.propTypes = {
  setSubmitButtonRef: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // isEditable: PropTypes.bool,
  deliveryWindow: PropTypes.shape({
    deliveryWindowStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deliveryWindowEnd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    deliveryDay: PropTypes.string,
  }),
};

DeliveryInfoForm.defaultProps = {
  // isEditable: false,
  deliveryWindow: {},
};

export default DeliveryInfoForm;
