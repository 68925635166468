import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

const CancelOrderModal = (props) => {
  const { isModalOpen, setIsModalOpen, handleSubmit } = props;

  CancelOrderModal.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  return (
    <Modal open={isModalOpen}>
      <Modal.Header>Bestellung stornieren</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h4>Sind Sie sicher, dass Sie die Bestellung stornieren wollen?</h4>
          <br />
        </Modal.Description>
        <Modal.Actions>
          <Button secondary onClick={() => setIsModalOpen(false)}>
            Abbrechen
          </Button>

          <Button negative onClick={handleSubmit}>
            Bestätigen
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default CancelOrderModal;
