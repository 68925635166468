import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import 'moment/locale/de';
import config from '../config';
import { GET_USER } from '../queries/UserQueries';

export const getMinimumWeight = () => {
  const userQuery = useQuery(GET_USER);

  let regions = [];
  let shopSelections = [];
  if (!userQuery.loading) {
    ({ regions, shopSelections } = userQuery.data.currentUser);
  }
  const normalMinWeight = _.get(config, `minWeight[${regions[0]}]`, config.minWeight.default);
  const hybridMinWeight = _.get(
    config,
    `minHybridWeight[${regions[0]}]`,
    config.minHybridWeight.default
  );

  const isHybrid = shopSelections.includes('Feldbotschaft');

  return isHybrid ? hybridMinWeight : normalMinWeight;
};
