import _ from 'lodash';

// turn articles from [{ containerNumber: 'B-1234', amount: 12, __typename: 'Article' }]
// into {'B-1234': { containerNumber: 'B-1234', amount: 12, __typename: 'Article' }}
// for faster lookup
export const toIdMap = (articles) => {
  return articles.reduce((result, article) => {
    result[article.articleId] = article;
    return result;
  }, {});
};

// takes array of articles and compares which ones are selected with which amount
export const selectArticles = (allArticles, selectedValues) => {
  allArticles = toIdMap(allArticles);

  return _.map(selectedValues, (amount, articleId) => {
    return {
      ...allArticles[articleId],
      amount,
    };
  });
};
