/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import Auth from '../services/AuthService';
import { SETUP_QUERY } from '../queries/SetupQueries';
import PageContainer from './PageContainer';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (Auth.isUserAuthenticated()) {
        return (
          <Query query={SETUP_QUERY}>
            {({ loading }) => {
              if (loading) return <Loader active />;

              return (
                <PageContainer>
                  <Component {...props} />
                </PageContainer>
              );
            }}
          </Query>
        );
      }

      return <Redirect to="/login" />;
    }}
  />
);

export default PrivateRoute;
