/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { Helmet } from 'react-helmet';
import { Grid, Card, Message, Header } from 'semantic-ui-react';
import { Button, Form, Input } from 'formik-semantic-ui';
import * as Yup from 'yup';
import { Query, Mutation } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import { Cookies, withCookies } from 'react-cookie';
import { VERIFY_TOKEN_QUERY, REGISTER_MUTATION } from '../queries/AuthenticationQueries';
import removeGQLError from '../helpers/remove-gql-error';
import config from '../config';
import headerLogo from '../assets/querfeld-logo.svg';
import feldbotschaftHost from '../assets/feldbotschaft_host.svg';
import shopIcon from '../assets/shop-white.svg';

class RegistrationForm extends Component {
  registerSchema = Yup.object().shape({
    firstName: Yup.string().required('Erforderlich'),
    lastName: Yup.string().required('Erforderlich'),
    email: Yup.string()
      .required('Erforderlich')
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Ungültige E-Mail'
      ),
    password: Yup.string()
      .required('Erforderlich')
      .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen'),
    passwordConfirm: Yup.string()
      .required('Erforderlich')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen'),
    feldbotschaftPrivacy: Yup.string().required('Erforderlich'),
    feldbotschaftEntryCode: Yup.string().when('feldbotschaftPrivacy', {
      is: 'private',
      then: Yup.string()
        .required('Erforderlich')
        .min(8, 'Passwort muss aus mindestens 8 Zeichen bestehen')
        .trim(),
    }),
    marketingEmailsWanted: Yup.boolean().required('Erforderlich'),
    tgcAccepted: Yup.boolean().oneOf([true], 'Bitte akzeptieren Sie unsere AGB'),
    dataPrivacyAccepted: Yup.boolean().oneOf(
      [true],
      'Bitte akzeptieren Sie unsere Datenschutzbestimmungen'
    ),
  });

  constructor() {
    super();
    this.state = {
      errorMessage: null,
      redirectHome: false,
      hasSubmitted: false,
    };
  }

  _handleSubmit = (mutation, customerId, shopSelections, values, formikApi) => {
    formikApi.setSubmitting(true);
    mutation({
      variables: {
        ...values,
        tgcAccepted: values.tgcAccepted ? moment().format() : 'ERR',
        dataPrivacyAccepted: values.dataPrivacyAccepted ? moment().format() : 'ERR',
        customerId,
        shopSelection: shopSelections[0],
        originApp: 'Portal',
      },
    })
      .then((response) => {
        const {
          data: {
            directCustomerRegister: { token },
          },
        } = response;
        const { cookies } = this.props;
        cookies.set('token', token, { path: '/', domain: config.cookieDomain });
        window.location.assign(
          shopSelections.includes('Feldbotschaft')
            ? `${config.feldbotschaft.baseURL}/login`
            : `${window.location.origin}/login`
        );
      })
      .catch((e) => {
        this.setState({ errorMessage: removeGQLError(e.message) });
      })
      .finally(() => formikApi.setSubmitting(false));
  };

  render() {
    const { errorMessage, redirectHome, hasSubmitted } = this.state;
    const { registerSchema, props } = this;
    const { token } = props.match.params;

    if (redirectHome) {
      return <Redirect to="/shop" />;
    }

    return (
      <div className="PageContainer">
        <div className="NavigationBarContainer">
          <img src={headerLogo} alt="Querfeld Logo" />
        </div>
        <Helmet>
          <title>Passwort festlegen - Querfeld</title>
        </Helmet>
        <Mutation mutation={REGISTER_MUTATION}>
          {(register) => (
            <Query query={VERIFY_TOKEN_QUERY} variables={{ token }}>
              {({ loading, error, data }) => {
                if (loading) return null;
                if (error) {
                  if (error.message.includes('Invalid Token')) {
                    return 'Ihr Registrierungslink ist nicht valide. Bitte kontaktieren Sie unseres Support!';
                  }

                  return 'Oops! Something went wrong, please try again.';
                }

                const { verifyToken } = data;

                return (
                  <Card className="ApplicationBox">
                    <div className="ApplicationBox__HeaderIconContainer">
                      {verifyToken && verifyToken.shopSelections[0] === 'Business' ? (
                        <img style={{ width: '48px' }} src={shopIcon} alt="" />
                      ) : (
                        <img src={feldbotschaftHost} alt="" />
                      )}
                    </div>
                    <Card.Content style={{ width: '100%' }}>
                      <Form
                        initialValues={{
                          firstName: verifyToken ? verifyToken.contacts[0].firstName : '',
                          lastName: verifyToken ? verifyToken.contacts[0].lastName : '',
                          email: verifyToken ? verifyToken.email : '',
                          password: '',
                          passwordConfirm: '',
                          feldbotschaftPrivacy: 'public',
                          feldbotschaftEntryCode: '',
                          tgcAccepted: true,
                          dataPrivacyAccepted: true,
                          marketingEmailsWanted:
                            verifyToken && verifyToken.marketingEmailsWanted
                              ? verifyToken.marketingEmailsWanted
                              : true,
                        }}
                        // Bind register mutation and customer ID to submit handler
                        // eslint-disable-next-line
                        onSubmit={this._handleSubmit.bind(
                          this,
                          register,
                          verifyToken.id,
                          verifyToken.shopSelections
                        )}
                        validationSchema={registerSchema}
                        validateOnBlur={hasSubmitted}
                      >
                        <Grid>
                          <Grid.Column>
                            <Header as="h1">Registrierung abschliessen</Header>
                            <Link to="/login">Sie haben bereits ein Kundenkonto?</Link>
                            <Header as="h2">Passwort festlegen</Header>
                            {errorMessage && (
                              <Message negative header="Hoppla!" content={errorMessage} />
                            )}
                            <div className="grid">
                              <Input
                                label="Passwort"
                                name="password"
                                inputProps={{
                                  type: 'password',
                                }}
                              />
                              <Input
                                label="Passwort wiederholden"
                                name="passwordConfirm"
                                inputProps={{
                                  type: 'password',
                                }}
                              />
                            </div>
                            <br />
                            <Button.Submit
                              style={{ padding: '0 50px', marginTop: '24px' }}
                              onClick={() => this.setState({ hasSubmitted: true })}
                            >
                              Passwort setzen
                            </Button.Submit>
                          </Grid.Column>
                        </Grid>
                      </Form>
                    </Card.Content>
                    <div className="ApplicationBox__footer">
                      Gibt es noch Fragen? <br />
                      <a
                        href={`${config.website.baseURL}/${
                          verifyToken && verifyToken.shopSelections[0] === 'Business'
                            ? 'faq_business'
                            : 'faq'
                        }?utm_source=businessapp&utm_medium=email&utm_campaign=businessapp`}
                      >
                        Hier gehts zu häufig gestellten Fragen
                      </a>
                    </div>
                  </Card>
                );
              }}
            </Query>
          )}
        </Mutation>
      </div>
    );
  }
}

RegistrationForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default withCookies(RegistrationForm);
