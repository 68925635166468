import React from 'react';
import { Card, Header, Accordion } from 'semantic-ui-react';
import { generalFAQs, shopFAQs } from '../helpers/FAQs';

const Contact = () => (
  <Card className="card-support">
    <Card.Content>
      <div className="faqsection">
        <Header as="h1" className="header--is-handwritten">
          Kundenservice
        </Header>
        <p className="centertext intro">
          Auf dieser Seite finden Sie Antworten auf häufig gestellte Fragen.
          <br />
          Falls Ihr Anliegen unbeantwortet bleibt, können Sie unser gerne über unser Ticket-System
          eine Anfrage stellen.
        </p>
        <div className="wrapper">
          <section>
            <Header as="h2" className="section">
              Häufig gestellte Fragen
            </Header>
            <Accordion panels={generalFAQs} exclusive={false} />
          </section>
          <section>
            <Header as="h2" className="section">
              Fragen zum Shop
            </Header>
            <Accordion panels={shopFAQs} exclusive={false} />
          </section>
        </div>
      </div>
    </Card.Content>
  </Card>
);

export default Contact;
