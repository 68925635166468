import gql from 'graphql-tag';

const orderFragment = gql`
  fragment orderValues on Order {
    id
    customerId
    customerNumber
    orderNumber
    orderDate
    status
    deliveryWindow {
      deliveryDay
      deliveryWindowStart
      deliveryWindowEnd
    }
    deliveryAddress {
      city
      company
      countryCode
      street1
      zipcode
    }
    orderItems {
      containerId
      containerNumber
      articleId
      produceQuantity
      containerQuantity
      stockIncrement
      unitName
      price
      name
      origin
      description
      taxType
      amount
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $orderItems: [orderItem]!
    $deliveryWindowStart: Int!
    $deliveryWindowEnd: Int!
    $deliveryDay: String!
    $customerOrderNumber: String
    $orderComment: String
  ) {
    createOrder(
      orderItems: $orderItems
      deliveryWindowStart: $deliveryWindowStart
      deliveryWindowEnd: $deliveryWindowEnd
      deliveryDay: $deliveryDay
      customerOrderNumber: $customerOrderNumber
      orderComment: $orderComment
      shopSelection: "Business"
    ) {
      ...orderValues
    }
  }
  ${orderFragment}
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $orderId: String!
    $orderItems: [orderItem]!
    $deliveryWindowStart: Int!
    $deliveryWindowEnd: Int!
    $deliveryDay: String!
  ) {
    updateOrder(
      orderId: $orderId
      orderItems: $orderItems
      deliveryWindowStart: $deliveryWindowStart
      deliveryWindowEnd: $deliveryWindowEnd
      deliveryDay: $deliveryDay
    ) {
      ...orderValues
    }
  }
  ${orderFragment}
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($orderId: String!) {
    cancelOrder(orderId: $orderId) {
      success
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders($startWeek: Int, $shopSelection: String!) {
    getOrders(startWeek: $startWeek, shopSelection: $shopSelection) {
      id
      orderDate
      orderNumber
      orderItems {
        amount
        price
        taxType
      }
      plannedShippingDate
      invoiced
      status
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($orderId: String!) {
    getOrder(orderId: $orderId) {
      ...orderValues
    }
  }
  ${orderFragment}
`;
