/* eslint react/jsx-no-bind: 0 */
/* eslint prefer-destructuring: 0 */
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Button, TextArea, Form, Input } from 'formik-semantic-ui';
import { Header, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { Redirect, Link } from 'react-router-dom';
import ArticleForm from './ArticleForm';
import Success from './Success';
import { selectArticles } from '../helpers/select-articles';
import { GET_USER } from '../queries/UserQueries';
import { GET_CURRENT_CART } from '../queries/ProductQueries';
import { CREATE_ORDER } from '../queries/OrderQueries';
import CartFooter from './CartFooter';
import DeliveryInfoForm from './DeliveryInfoForm';
import CartStore from '../services/CartStoreService';
import { client } from '../index';
import { calcTotalPrice, calcTotalWeight } from '../helpers/totals';
import cartIcon from '../assets/cart-icon-white.svg';
import FoodSavedCounter from './FoodSavedCounter';
import { getMinimumWeight } from '../helpers/minimum-weight';
import { trackPurchase } from '../helpers/tracking';

const Checkout = () => {
  const userQuery = useQuery(GET_USER);
  const cartQuery = useQuery(GET_CURRENT_CART);
  const [submitting, setSubmitting] = useState(false);
  const [totalOrdered, setTotalOrdered] = useState();
  const minWeight = getMinimumWeight();
  let deliveryInfoSubmitRef;
  let commentSubmitRef;
  let customerOrderNumber;
  let orderComment;
  let totalWeight;

  let currentUser = {};
  let cart = { articles: [], total: 0 };

  document.body.classList.add('checkout-view');

  if (!userQuery.loading) {
    ({ currentUser } = userQuery.data);
  }
  if (!cartQuery.loading && cartQuery.data) {
    ({ cart } = cartQuery.data);
    if (cart.articles.length === 0 && !totalOrdered) return <Redirect to="/shop" />;
    totalWeight = calcTotalWeight(cart.articles);
  }

  const handleSubmit = async (values, formikApi) => {
    const orderItems = cart.articles.map((item) => ({
      articleId: item.containerId,
      quantity: item.amount / item.stockIncrement,
    }));

    try {
      const variables = {
        orderItems,
        deliveryWindowStart: values.deliveryWindowStart,
        deliveryWindowEnd: values.deliveryWindowEnd,
        deliveryDay: values.deliveryDay,
        customerOrderNumber,
        orderComment,
      };
      const { data } = await client.mutate({ mutation: CREATE_ORDER, variables });
      setTotalOrdered(totalWeight);
      trackPurchase(
        {
          id: data.createOrder.id,
          source: 'invoice',
        },
        cart
      );
      await CartStore.clearCart();
      formikApi.setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      throw new Error(e);
    }
  };

  if (totalOrdered) {
    return (
      <Success
        headerWidget={<FoodSavedCounter totalSaved={totalOrdered} />}
        header="Vielen Dank für Ihre Bestellung"
        className="header-widget"
      >
        <p>
          Wir haben Ihre Bestellung dankend empfangen. Sie erhalten in Kürze eine Bestätigung per
          E-Mail.
        </p>
        <p>
          Sie können sich Ihre Bestellungen jederzeit <Link to="/shop">im Shop </Link>
          anzeigen lassen.
        </p>
      </Success>
    );
  }

  if (userQuery.loading || cartQuery.loading) return <Loader active inline="centered" />;
  return (
    <Dimmer.Dimmable>
      <Dimmer inverted active={submitting}>
        <Loader />
      </Dimmer>
      <div className="checkout noteditable">
        <Link to="./shop">
          <Icon name="arrow left" />
          zurück zum Shop
        </Link>
        <div className="view-header">
          <Header as="h1" className="ui header header--is-handwritten">
            Bestellung abschließen
          </Header>
        </div>
        <div className="customer-information">
          <DeliveryInfoForm
            setSubmitButtonRef={(el) => {
              deliveryInfoSubmitRef = el;
            }}
            onSubmit={handleSubmit}
            className="button"
            deliveryWindow={{
              deliveryWindowStart: currentUser.deliveryWindowStart,
              deliveryWindowEnd: currentUser.deliveryWindowEnd,
              deliveryDay: currentUser.deliveryDay,
            }}
            isEditable
          />
          <Form
            initialValues={{ customerOrderNumber: '', orderComment: '' }}
            validateOnBlur={false}
            onSubmit={(values) => {
              customerOrderNumber = values.customerOrderNumber;
              orderComment = values.orderComment;
              deliveryInfoSubmitRef.click();
            }}
          >
            <TextArea
              name="orderComment"
              fieldProps={{ className: 'is-optional' }}
              label="Kommentar zu Ihrer Bestellung"
            />
            <Input
              name="customerOrderNumber"
              fieldProps={{ className: 'is-optional' }}
              label="Kundeninterne Bestellnummer"
            />
            <button
              hidden
              type="submit"
              ref={(el) => {
                commentSubmitRef = el;
              }}
            />
          </Form>
        </div>
        <Header as="h3">Artikel überprüfen:</Header>
        <p className="summary-intro">
          Hier finden Sie die Zusammenfassung Ihrer Bestellung. Bitte bedenken Sie, dass bei jeder
          Bestellung zusätzliche Pfandgebühren anfallen können. Die Lieferung ist für Sie
          kostenfrei.
        </p>
        {minWeight > 0 && (
          <Header as="h3">
            {cart.articles.length} Artikel ({totalWeight}/{minWeight} kg Mbm.)
          </Header>
        )}
        <ArticleForm
          className="cart"
          articles={cart.articles}
          isCart
          isCheckout
          render={({ values }) => {
            const selectedArticles = selectArticles(cart.articles, values);
            const { grossTotal, netTotal, taxTotal } = calcTotalPrice(selectedArticles);
            return <CartFooter netTotal={netTotal} taxTotal={taxTotal} grossTotal={grossTotal} />;
          }}
        />
        <div className="checkout-buttons">
          <span className="left">
            <Button className="ui cart-icon">
              <img src={cartIcon} alt="" />
              <span className="smalltext">Bestellung abschließen</span>
            </Button>
          </span>
          <span className="right">
            <Link to="/shop">
              <Button secondary type="button">
                Bestellung bearbeiten
              </Button>
            </Link>
            <Button.Submit
              disabled={submitting}
              onClick={() => {
                commentSubmitRef.click();
                setSubmitting(true);
              }}
              content="Bestellung abschließen"
            />
          </span>
        </div>
      </div>
    </Dimmer.Dimmable>
  );
};

export default Checkout;
