import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Sidebar, Menu, Segment, Dropdown, Loader } from 'semantic-ui-react';
import { Redirect, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import Auth from '../services/AuthService';
import CartContainer from './CartContainer';
import headerLogo from '../assets/querfeld-logo.svg';
import userIcon from '../assets/user-icon.svg';
import supportIcon from '../assets/support-icon.svg';
import cartIcon from '../assets/cart-icon.svg';
import SidebarContext from '../contexts/SidebarContext';
import { client } from '../index';
import TermsModal from './TermsModal';
import ReviewModal from './ReviewModal';
import { FOOD_WASTE_REPORTS, GET_GREETING_DATA, GET_USER } from '../queries/UserQueries';
import config from '../config';
import { trackViewCart } from '../helpers/tracking';
import { GET_CURRENT_CART } from '../queries/ProductQueries';

const logOut = (setRedirectLogin) => {
  Auth.deauthenticateUser();
  setRedirectLogin(true);
  client.resetStore();
};

let accountDropdownRef;
let supportDropdownRef;

const PageContainer = withRouter(({ children, history }) => {
  const userQuery = useQuery(GET_USER);
  const foodWasteQuery = useQuery(FOOD_WASTE_REPORTS);
  const cartQuery = useQuery(GET_CURRENT_CART);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  let isFeldbotschaftUser = false;
  let isIndirectSalesChannel = false;
  if (!userQuery.loading && !userQuery.error) {
    isFeldbotschaftUser = userQuery.data.currentUser.shopSelections.includes('Feldbotschaft');
    isIndirectSalesChannel = ['NET3', 'NET7', 'NET20'].includes(
      userQuery.data.currentUser.salesChannel
    );
  }

  let hasReports = false;
  let latestDocument;
  if (!foodWasteQuery.loading && !foodWasteQuery.error) {
    hasReports = foodWasteQuery.data.foodWasteReports.length > 0;
    if (hasReports) {
      [latestDocument] = foodWasteQuery.data.foodWasteReports.sort((a, b) =>
        parseInt(a.lastModifiedDate, 10) > parseInt(b.lastModifiedDate, 10) ? -1 : 1
      );
    }
  }

  let currentCart;
  if (!cartQuery.loading && !cartQuery.error) {
    currentCart = cartQuery.data.cart.articles;
  }

  const hideSidebar = () => setSidebarVisible(false);
  const showSidebar = () => {
    setSidebarVisible(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    trackViewCart(currentCart);
  };

  if (userQuery.loading) return <Loader active inline="centered" />;
  if (redirectLogin) return <Redirect to="/login" />;

  return (
    <Sidebar.Pushable as={Segment}>
      <Sidebar
        as={Menu}
        animation="overlay"
        direction="right"
        icon="labeled"
        vertical
        visible={sidebarVisible}
      >
        <Menu.Item>
          <CartContainer onHide={hideSidebar} />
        </Menu.Item>
      </Sidebar>

      {/* Contains all content beneath sidebar */}
      <Sidebar.Pusher dimmed={sidebarVisible}>
        <Menu className="main-menu" icon="labeled">
          <Menu.Item name="home" onClick={() => history.push('/')}>
            <img src={headerLogo} alt="Querfeld" className="logo" />
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item name="support" onClick={() => supportDropdownRef.toggle()}>
              <Dropdown
                trigger={<img src={supportIcon} alt="" />}
                icon={null}
                ref={(element) => {
                  supportDropdownRef = element;
                }}
              >
                <Dropdown.Menu>
                  <Dropdown.Item text="FAQ" onClick={() => history.push('/faq')} />
                  <Dropdown.Item text="Kontaktformular" onClick={() => history.push('/contact')} />
                  <Dropdown.Item
                    text="Reklamationsformular"
                    onClick={() => history.push('/reclamation')}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <span>Support</span>
            </Menu.Item>

            <Menu.Item name="cart" onClick={showSidebar}>
              <div role="listbox" aria-expanded="false" className="ui" tabIndex="0">
                <img src={cartIcon} alt="" />
              </div>
              <span>Warenkorb</span>
            </Menu.Item>

            <Menu.Item name="account" onClick={() => accountDropdownRef.toggle()}>
              <Dropdown
                trigger={<img src={userIcon} alt="" />}
                icon={null}
                ref={(element) => {
                  accountDropdownRef = element;
                }}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Meine Bestellungen"
                    onClick={() => history.push('/order-history')}
                  />
                  <Dropdown.Item
                    text="Kontoinformation"
                    onClick={() => history.push('/account-details')}
                  />
                  {isFeldbotschaftUser && (
                    <Dropdown.Item
                      text="Zum Feldbotschaftkonto"
                      onClick={() =>
                        window.location.assign(`${config.feldbotschaft.baseURL}/manage`)
                      }
                    />
                  )}
                  {hasReports && (
                    <Dropdown.Item
                      text="Mein Food-Save-Report"
                      onClick={() => latestDocument && window.open(latestDocument.url, '_blank')}
                    />
                  )}
                  <Dropdown.Item
                    text="Marketingmaterial"
                    onClick={() =>
                      window.open(
                        isIndirectSalesChannel
                          ? 'https://drive.google.com/drive/folders/10KkP9pWKE7KMMFwROAyKy6pQznwITGi0'
                          : 'https://drive.google.com/drive/folders/1XBXIdmWS6PWyEt09kieHlKVkURd-yBnu',
                        '_blank'
                      )
                    }
                  />
                  <Dropdown.Item text="Ausloggen" onClick={() => logOut(setRedirectLogin)} />
                </Dropdown.Menu>
              </Dropdown>
              <span>Account</span>
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <Container className="main-container">
          <SidebarContext.Provider value={{ showSidebar }}>
            <Query query={GET_GREETING_DATA}>
              {({ error, loading, data }) => {
                if (loading || error) return children;
                const { currentUser, reviews } = data;
                const { tgcAccepted, dataPrivacyAccepted, recentOrderId } = currentUser;

                const tgcNeedsUpdate = moment(tgcAccepted) < moment('2019-08-05');
                const dataPrivacyNeedsUpdate = moment(dataPrivacyAccepted) < moment('2023-02-18');

                const reviewNeeded = !reviews.find((r) => Number(r.salesOrderId) === recentOrderId);
                const currentDay = moment();
                const recentDeliveryDay = moment(`${currentUser.recentDeliveryDate}`, 'x');
                const validReviewPeriod =
                  currentDay.diff(recentDeliveryDay, 'days') >= 0 &&
                  currentDay.diff(recentDeliveryDay, 'days') < 14;

                return (
                  <>
                    <TermsModal
                      defaultOpen={tgcNeedsUpdate || dataPrivacyNeedsUpdate}
                      includeTGC={tgcNeedsUpdate}
                      includeDataPrivacy={dataPrivacyNeedsUpdate}
                    />
                    <ReviewModal
                      defaultOpen={reviewNeeded && validReviewPeriod}
                      user={currentUser}
                    />
                    {children}
                  </>
                );
              }}
            </Query>
          </SidebarContext.Provider>
        </Container>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
});

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageContainer;
