import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Button, Popup, Message } from 'semantic-ui-react';
import { Route } from 'react-router-dom';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import ArticleForm from './ArticleForm';
import { selectArticles } from '../helpers/select-articles';
import { calcTotalPrice, calcTotalWeight } from '../helpers/totals';
import CartFooter from './CartFooter';
import { getMinimumWeight } from '../helpers/minimum-weight';
import { GET_USER } from '../queries/UserQueries';

const Cart = ({
  articles,
  onClose,
  onSubmit,
  closeButtonText,
  submitButtonText,
  isOrderDetails,
}) => {
  const userQuery = useQuery(GET_USER);
  const [deletedArticleCount, setDeletedArticleCount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(calcTotalWeight(articles));
  const minWeight = getMinimumWeight();

  return (
    <div className="cart">
      {!isOrderDetails && minWeight > 0 && (
        <Header as="h3">
          {articles.length - deletedArticleCount} Artikel ({totalWeight}/{minWeight} kg Mbm.)
        </Header>
      )}
      <ArticleForm
        className="cart"
        articles={articles}
        isCart
        render={({ values, deletedArticles, resetDeleted, validate }) => {
          setDeletedArticleCount(_.size(deletedArticles));
          const selectedArticles = selectArticles(articles, values);
          setTotalWeight(calcTotalWeight(selectedArticles));
          const { grossTotal, netTotal, taxTotal } = calcTotalPrice(selectedArticles);
          const isViewOnlyUser = _.get(userQuery, 'data.currentUser.salesChannel') === 'NET20'; // Some customers have access to view offer but cannot order
          const isValid =
            articles.length - deletedArticleCount > 0 &&
            totalWeight >= minWeight &&
            !isViewOnlyUser;

          return (
            <>
              <CartFooter netTotal={netTotal} grossTotal={grossTotal} taxTotal={taxTotal} />
              {isViewOnlyUser && (
                <Message
                  visible
                  warning
                  icon="warning sign"
                  content={
                    <>
                      Liebe Kund*innen, zum Bestellen von Querfeld-Produkten nutzen Sie bitte Ihr
                      hauseigenes Bestellsystem. Unsere Artikel sind dort mit den Begriffen
                      &quot;Querfeld&quot; und &quot;Bio&quot; gekennzeichnet. Bei Fragen, können
                      Sie sich gerne unter{' '}
                      <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>{' '}
                      oder 030-340603470 melden.
                    </>
                  }
                />
              )}
              {/* START: Holiday timing logic */}
              {moment().isBetween(
                moment('2023-09-20').startOf('day'),
                moment('2023-10-03').endOf('day')
              ) && (
                <Message
                  visible
                  warning
                  icon="warning sign"
                  content={
                    <>
                      Für Kunden mit dem Liefertag Dienstag, den 03.10.2023, findet die Anlieferung
                      aufgrund des Feiertags einen Tag später am Mittwoch, den 04.10.2023, statt.
                    </>
                  }
                />
              )}
              {/* END: Holiday timing logic */}
              <Route
                className="cart-actions"
                render={({ history }) => (
                  <>
                    <Button
                      secondary
                      type="button"
                      onClick={() =>
                        onClose({
                          validate,
                          selectedArticles,
                          netTotal,
                          resetDeleted,
                          history,
                        })
                      }
                    >
                      {closeButtonText}
                    </Button>
                    <Popup
                      content={`Mindestbestellmenge von ${minWeight} kg nicht erreicht.`}
                      className={isOrderDetails ? false : 'cart-popup'}
                      trigger={
                        <span id="submitButton">
                          <Button
                            primary
                            disabled={isOrderDetails ? false : !isValid}
                            type="button"
                            onClick={() =>
                              onSubmit({
                                validate,
                                selectedArticles,
                                netTotal,
                                resetDeleted,
                                history,
                              })
                            }
                          >
                            {submitButtonText}
                          </Button>
                        </span>
                      }
                      disabled={isOrderDetails ? true : isValid}
                    />
                  </>
                )}
              />
            </>
          );
        }}
      />
    </div>
  );
};

Cart.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeButtonText: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  isOrderDetails: PropTypes.bool,
};

Cart.defaultProps = {
  isOrderDetails: false,
};

export default Cart;
