import _ from 'lodash';
import { GET_CURRENT_CART } from '../queries/ProductQueries';
import { toIdMap } from '../helpers/select-articles';

export default {
  Mutation: {
    addToCart: (args, { articles }, { cache }) => {
      const { cart } = cache.readQuery({ query: GET_CURRENT_CART });

      // turn cart articles from [{ articleId: 'B-1234', amount: 12, __typename: 'Article' }]
      // into {'B-1234': { articleId: 'B-1234', amount: 12, __typename: 'Article' }}
      // for faster lookup
      const cartArticles = toIdMap(cart.articles);
      articles = toIdMap(articles);

      // loop over each article that was added from the form
      // if article is found in current cart, add amount, else create new article in cart
      _.forIn(articles, (value, articleId) => {
        const cartArticle = cartArticles[articleId];
        const selectedArticle = articles[articleId];
        let { amount } = selectedArticle;
        amount = Number(amount);

        if (amount === 0) cartArticles[articleId] = null;
        else if (cartArticle) cartArticle.amount = amount;
        else cartArticles[articleId] = { ...selectedArticle };
      });

      const data = {
        cart: {
          __typename: 'Cart',
          // return cart articles into array format for graphql :/
          articles: _.map(cartArticles, (a) => a).filter(Boolean),
          total: _.reduce(
            cartArticles,
            (total, a) => {
              if (!a) return total;
              total += a.amount * a.price;
              return total;
            },
            0
          ),
        },
      };

      // throws "uncontrolled to controlled" warning
      cache.writeQuery({ query: GET_CURRENT_CART, data });
      return data;
    },

    commitCart: (args, { articles, total }, { cache }) => {
      const data = {
        cart: {
          __typename: 'Cart',
          articles,
          total,
        },
      };

      cache.writeQuery({ query: GET_CURRENT_CART, data });
      return null;
    },
  },
};
