/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import moment from 'moment';
import { Header, Loader, Button, Icon, Message } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { GET_ORDER, GET_ORDERS, UPDATE_ORDER, CANCEL_ORDER } from '../queries/OrderQueries';
import Cart from './Cart';
import ArticleForm from './ArticleForm';
import Success from './Success';
import { selectArticles } from '../helpers/select-articles';
import { calcTotalPrice } from '../helpers/totals';
import CartFooter from './CartFooter';
import DeliveryInfoForm from './DeliveryInfoForm';
import { client } from '../index';
import ConfirmOrderModal from './ConfirmOrderModal';
import CancelOrderModal from './CancelOrderModal';
import removeGqlError from '../helpers/remove-gql-error';

const OrderDetails = () => {
  const { orderId } = useParams();
  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: { orderId },
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [editedOrderArticles, setEditedOrderArticles] = useState([]);
  const [deliveryWindow, setDeliveryWindow] = useState();
  let submitButtonRef;

  const setSubmitButtonRef = (element) => {
    submitButtonRef = element;
  };

  const handleSubmit = async () => {
    const orderItems = editedOrderArticles.map(({ containerId, containerQuantity }) => {
      return {
        articleId: containerId,
        quantity: containerQuantity,
      };
    });

    try {
      const variables = {
        orderId,
        orderItems,
        deliveryDay: deliveryWindow.deliveryDay,
        deliveryWindowStart: Number(deliveryWindow.deliveryWindowStart),
        deliveryWindowEnd: Number(deliveryWindow.deliveryWindowEnd),
      };
      await client.mutate({
        mutation: UPDATE_ORDER,
        variables,
        refetchQueries: [{ query: GET_ORDERS }, { query: GET_ORDER, variables: { orderId } }],
      });
      setIsSuccessful(true);
    } catch (e) {
      throw new Error(e);
    }
  };

  const handleCancel = async () => {
    try {
      const variables = {
        orderId,
      };
      await client.mutate({
        mutation: CANCEL_ORDER,
        variables,
        refetchQueries: [{ query: GET_ORDERS }, { query: GET_ORDER, variables: { orderId } }],
      });
      setIsCanceled(true);
    } catch (e) {
      throw new Error(e);
    }
  };

  if (isCanceled) {
    return (
      <Success header="Ihre Bestellung wurde erfolgreich storniert">
        <p>
          Wir habendie Bestellung ihrem Wunsch entsprechend storniert. Sie erhalten in Kürze eine
          Bestätigung per E-Mail. Sie können sich Ihre aktuellen Bestellungen jederzeit im Shop
          anzeigen lassen.
          <Link to="/order-history">Zurück zur Bestellübersicht</Link>
        </p>
      </Success>
    );
  }

  if (isSuccessful) {
    return (
      <Success header="Danke für die Aktualisierung Ihrer Bestellung!">
        <p>
          Der Auftrag wurde erfolgreich aktualisiert und wird nun vom System verarbeitet. Sollte ein
          Produkt wider Erwarten nicht lieferbar sein, informieren wir Sie darüber frühzeitig.
          <Link to="/order-history" className="standalone-link">
            Zurück zur Bestellübersicht
          </Link>
        </p>
      </Success>
    );
  }

  if (loading) return <Loader active inline="centered" />;
  if (error) return <Message negative header="Hoppla!" content={removeGqlError(error.message)} />;

  const {
    customerNumber,
    orderItems,
    orderDate,
    orderNumber,
    status,
    deliveryWindow: orderDeliveryWindow,
  } = data.getOrder;
  if (!deliveryWindow) {
    setDeliveryWindow(orderDeliveryWindow);
  }

  const editOrderCutoffDate = moment(Number(orderDate)).day(3).hour(12).startOf('hour');
  const isEditable = moment() < editOrderCutoffDate && !(status === 'CANCELLED');

  return (
    <div className="checkout view-order">
      <div className={isEditable ? 'editable' : 'noteditable'}>
        <Link to="../order-history" className="back-to-orderhistory">
          <Icon name="arrow left" />
          zurück zur Bestellübersicht
        </Link>
        <Header as="h2">Bestellung {orderNumber}</Header>
        <div className="meta">Kundennummer: {customerNumber}</div>
        <DeliveryInfoForm
          isEditable={isEditable}
          setSubmitButtonRef={setSubmitButtonRef}
          onSubmit={(values, formikApi) => {
            setDeliveryWindow(values);
            setIsConfirmModalOpen(true);
            formikApi.setSubmitting(false);
          }}
          deliveryWindow={deliveryWindow}
        />
        <Header as="h3">Artikel überprüfen:</Header>
        <p className="summary-intro">
          Hier finden Sie die Zusammenfassung Ihrer Bestellung. Bitte bedenken Sie, dass bei jeder
          Bestellung zusätzliche Pfandgebühren anfallen können. Die Lieferung ist für Sie
          kostenfrei.
        </p>
        {isEditable ? (
          <>
            <Cart
              isOrderDetails
              articles={orderItems}
              closeButtonText="Änderungen verwerfen"
              submitButtonText="Änderungen übernehmen"
              onClose={async () => {
                window.location.reload();
              }}
              onSubmit={({ selectedArticles }) => {
                selectedArticles.forEach((article) => {
                  article.containerQuantity = article.amount / article.stockIncrement;
                });
                setEditedOrderArticles(selectedArticles);
                submitButtonRef.click();
              }}
            />
            <Button color="red" type="button" onClick={() => setIsCancelModalOpen(true)}>
              Bestellung stornieren
            </Button>
          </>
        ) : (
          <ArticleForm
            className="cart"
            articles={orderItems}
            isCart
            isCheckout
            render={({ values }) => {
              const selectedArticles = selectArticles(orderItems, values);
              const { grossTotal, netTotal, taxTotal } = calcTotalPrice(selectedArticles);
              return <CartFooter netTotal={netTotal} grossTotal={grossTotal} taxTotal={taxTotal} />;
            }}
          />
        )}
        <ConfirmOrderModal
          orderItems={editedOrderArticles}
          isModalOpen={isConfirmModalOpen}
          setIsModalOpen={setIsConfirmModalOpen}
          handleSubmit={handleSubmit}
        />
        <CancelOrderModal
          isModalOpen={isCancelModalOpen}
          setIsModalOpen={setIsCancelModalOpen}
          handleSubmit={handleCancel}
        />
      </div>
      <div className="footer">
        <span>
          Benötigen Sie Hilfe oder haben Sie Fragen zu Ihrer Bestellung?
          <br />
          Besuchen Sie unsere <Link to="/contact">Hilfeseite</Link> oder schreiben Sie uns an{' '}
          <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
        </span>
      </div>
    </div>
  );
};

export default OrderDetails;
