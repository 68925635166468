import React, { Component } from 'react';
import { Grid, Card, Message, Header } from 'semantic-ui-react';
import { Button, Form, Input } from 'formik-semantic-ui';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import { FORGOT_PASSWORD_MUTATION } from '../queries/AuthenticationQueries';
import AuthContainer from './AuthContainer';

class ForgotPasswordForm extends Component {
  // define validation schema
  emailSchema = Yup.object().shape({
    email: Yup.string()
      .required('Erforderlich')
      .matches(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Ungültige E-Mail'
      ),
  });

  constructor() {
    super();
    this.state = {
      isFailed: false,
      isSuccessful: false,
      hasSubmitted: false,
    };
  }

  _handleSubmit = (mutation, values, formikApi) => {
    formikApi.setSubmitting(true);
    // Make API Call
    mutation({
      variables: {
        email: values.email,
      },
    })
      .then(() => {
        this.setState({ isFailed: false });
        this.setState({ isSuccessful: true });
      })
      .catch(() => {
        this.setState({ isSuccessful: false });
        this.setState({ isFailed: true });
      })
      .finally(() => formikApi.setSubmitting(false));
  };

  render() {
    const { isFailed, isSuccessful, hasSubmitted } = this.state;
    const { emailSchema } = this;

    return (
      <AuthContainer>
        <Helmet>
          <title>Passwort vergessen - Querfeld</title>
        </Helmet>
        <Mutation mutation={FORGOT_PASSWORD_MUTATION}>
          {(forgotPassword) => (
            <Card className="card-auth">
              <Card.Content>
                <Form
                  initialValues={{
                    email: '',
                  }}
                  // bind mutation to _handleSubmit so query can be accessed
                  // eslint-disable-next-line
                  onSubmit={this._handleSubmit.bind(this, forgotPassword)}
                  validationSchema={emailSchema}
                  validateOnBlur={hasSubmitted}
                >
                  <Grid stackable>
                    <Grid.Column>
                      <Header as="h1">Passwort vergessen</Header>
                      <p className="greytext">
                        Bitte unten in das Feld die registrierte E-Mail Adresse eingeben und schon
                        können wir eine E-Mail mit einem Link zum Zurücksetzen des Passworts
                        losschicken.
                      </p>
                      {isSuccessful && (
                        <Message
                          positive
                          header="Yay!"
                          content="Wenn es einen Account mit dieser Adresse gibt, haben wir eine
                          E-Mail losgeschickt, in der alle weiteren Anweisungen stehen."
                        />
                      )}
                      {isFailed && (
                        <Message
                          negative
                          header="Hoppla!"
                          content="Irgendwas ist schief gelaufen, bitte versuchen Sie es noch einmal."
                        />
                      )}
                      <Input label="E-Mail" name="email" />
                      <br />
                      <Button.Submit onClick={() => this.setState({ hasSubmitted: true })}>
                        Absenden
                      </Button.Submit>
                    </Grid.Column>
                  </Grid>
                </Form>
              </Card.Content>
            </Card>
          )}
        </Mutation>
      </AuthContainer>
    );
  }
}

export default ForgotPasswordForm;
