import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Loader, Dimmer } from 'semantic-ui-react';
import ArticleForm from './ArticleForm';
import { selectArticles } from '../helpers/select-articles';
import { calcTotalPrice } from '../helpers/totals';
import CartFooter from './CartFooter';

const ConfirmOrderModal = (props) => {
  const { orderItems, isModalOpen, setIsModalOpen, handleSubmit } = props;
  const [submitting, setSubmitting] = useState(false);

  ConfirmOrderModal.propTypes = {
    orderItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  return (
    <Modal open={isModalOpen} className="confirm-changed-order">
      <Modal.Header>Bestätigen Sie Ihre Bestellung</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Dimmer active={submitting} inverted>
            <Loader active={submitting} />
          </Dimmer>
          <ArticleForm
            className="cart"
            articles={orderItems}
            isCart
            isCheckout
            render={({ values }) => {
              const selectedArticles = selectArticles(orderItems, values);
              const { grossTotal, netTotal, taxTotal } = calcTotalPrice(selectedArticles);
              return <CartFooter netTotal={netTotal} grossTotal={grossTotal} taxTotal={taxTotal} />;
            }}
          />
        </Modal.Description>
        <Modal.Actions>
          <Button secondary onClick={() => setIsModalOpen(false)}>
            Abbrechen
          </Button>
          <Button
            primary
            onClick={() => {
              setSubmitting(true);
              handleSubmit();
            }}
          >
            Bestätigen
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmOrderModal;
