/* eslint react/no-array-index-key: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Grid, Card, Message, Header, List, Dropdown, Segment } from 'semantic-ui-react';
import { Button, Form, Input, TextArea } from 'formik-semantic-ui';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { GET_ORDERS } from '../queries/OrderQueries';
import { RECLAMATION_MUTATION } from '../queries/SupportQueries';

const Reclamation = () => {
  const ordersQuery = useQuery(GET_ORDERS, {
    variables: { shopSelection: 'Business' },
  });
  const [orderId, setOrderId] = useState('');
  const [orderOptions, setOrderOptions] = useState([]);
  const [reason, setReason] = useState('quality');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  if (!ordersQuery.loading && !orderId) {
    const orders = ordersQuery.data.getOrders;
    if (orders[0]) {
      setOrderId(orders[0].id);
      setOrderOptions(
        orders.map((o) => {
          return {
            value: o.id,
            text: `Auftrag ${o.orderNumber} geliefert am ${moment(
              `${o.plannedShippingDate}`,
              'x'
            ).format('D.MM.YYYY')}`,
          };
        })
      );
    }
  }

  return (
    <Card className="card-support">
      <Card.Content className="reclamation">
        <div className="ui form formsection dark-bg" id="feedback-form">
          <div className="content field">
            <Header as="h1" className="header--is-handwritten">
              Reklamation
            </Header>
            <p className="intro">
              Sie möchten ein Produkt reklamieren? Um Ihre Reklamation prüfen zu können bitten wir
              Sie das folgende Formular vollständig auszufüllen. Ein zuständiger Mitarbeiter wird
              sich um Ihr Anliegen kümmern und meldet sich bei Ihnen. Vielen Dank.
            </p>
            {success && (
              <Message
                positive
                header="Erfolg!"
                content="Der Teufel steckt bekanntlich im Detail und nur mit Ihrer Hilfe
                können, wir auch die letzten Fehler oder Ungereimtheiten im Shop ausmerzen.
                Wir schauen uns Ihr anliegen an und melden uns schnellstmöglich bei Ihnen."
              />
            )}
            {errorMessage && <Message negative header="Hoppla!" content={errorMessage} />}
            <label>
              Um welche Bestellung handelt es sich? <span>*</span>
            </label>
            <Dropdown
              selection
              value={orderId}
              loading={ordersQuery.loading}
              onChange={(e, { value }) => setOrderId(value)}
              options={orderOptions}
            />
            <label>
              Grund für Ihre Reklamation? <span>*</span>
            </label>
            <Dropdown
              selection
              value={reason}
              onChange={(e, { value }) => setReason(value)}
              options={[
                { value: 'quantity', text: 'Fehlmenge' },
                { value: 'quality', text: 'Mangelnde Qualität' },
              ]}
            />
            <div className="message-wrapper" hidden={reason === 'quantity'}>
              <Segment className="ui message neutral">
                <p className="header">Anforderungen an eine gültige Reklamation:</p>
                <List ordered>
                  <List.Item>
                    Reklamation innerhalb von 24 Stunden nach Lieferung aufgegeben
                  </List.Item>
                  <List.Item>Menge muss signifikant sein (mehr als 5% des Produkts)</List.Item>
                  <List.Item>Es muss ein Foto angehangen werden</List.Item>
                </List>
              </Segment>
            </div>
            <ReclamationForm
              orderId={orderId}
              reason={reason}
              setSuccess={setSuccess}
              setErrorMessage={setErrorMessage}
            />
          </div>
        </div>
      </Card.Content>
    </Card>
  );
};

const ReclamationForm = ({ orderId, reason, setSuccess, setErrorMessage }) => {
  const [sendReclamation] = useMutation(RECLAMATION_MUTATION);
  const [initialValues, setInitialValues] = useState({
    returnArticles: [{ name: '', amount: '' }],
    pictures: [],
    comment: '',
  });
  const validationSchema = buildSchema();
  const articleLabel =
    reason === 'quality'
      ? 'Folgende Artikel möchte ich reklamieren: '
      : 'Folgende Artikel haben gefehlt: ';
  let uploadRef;

  function buildSchema() {
    const schema = {
      returnArticles: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Erforderlich'),
          amount: Yup.string().required('Erforderlich'),
        })
      ),
    };
    if (reason === 'quality') schema.pictures = Yup.array().required('Erforderlich');

    return Yup.object(schema);
  }

  const handleFile = (file, form) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const MAX_WIDTH = 700;
        const MAX_HEIGHT = 700;
        let { width, height } = img;

        if (width > height && width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            blob.name = file.name;
            form.setFieldValue('pictures', [...form.values.pictures, blob]);
          },
          'image/jpeg',
          0.85
        );
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (values, formikApi) => {
    if (!orderId) {
      setErrorMessage('Bitte wählen Sie eine Bestellung aus');
      formikApi.setSubmitting(false);
      return;
    }
    try {
      if (reason === 'quantity') values.pictures = [];
      const variables = { orderId, reason, ...values };

      await sendReclamation({ variables });
      setSuccess(true);
      setErrorMessage(null);
    } catch (e) {
      setSuccess(false);
      setErrorMessage(e);
    } finally {
      setInitialValues({
        returnArticles: [{ name: '', amount: '' }],
        pictures: [],
      });
      formikApi.resetForm();
      formikApi.setSubmitting(false);
    }
  };

  const DeleteButton = (articles, index) => (
    <Button
      onClick={() => {
        articles.splice(index, 1);
        setInitialValues({
          ...initialValues,
          returnArticles: articles,
        });
      }}
      className="red"
    >
      X
    </Button>
  );

  return (
    <Form
      className="supportform"
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {(form) => (
        <>
          <Grid columns={3}>
            {Object.values(form.values.returnArticles).map((a, i) => {
              return (
                <Grid.Row key={`row${i}`}>
                  <Grid.Column width={10}>
                    <Input
                      name={`returnArticles[${i}].name`}
                      label={i === 0 && articleLabel}
                      inputProps={{ placeholder: 'Artikelname' }}
                    />
                    <ErrorMessage
                      component="div"
                      className="smalltext error"
                      name={`returnArticles[${i}].name`}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} className="amount">
                    <Input
                      name={`returnArticles[${i}].amount`}
                      label={i === 0 && 'Menge & Einheit: '}
                      inputProps={{ placeholder: 'Mengenangabe' }}
                    />
                    {i !== 0 && DeleteButton(form.values.returnArticles, i)}
                    <ErrorMessage
                      component="div"
                      className="smalltext error"
                      name={`returnArticles[${i}].amount`}
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <Button
            secondary
            className="add-article"
            onClick={() =>
              setInitialValues({
                ...initialValues,
                returnArticles: [...form.values.returnArticles, { name: '', amount: '' }],
              })
            }
          >
            + weiteren Artikel angeben
          </Button>
          <span hidden={reason === 'quantity'} className="upload-wrapper">
            <label>
              Fotos der betroffenen Ware: <span>*</span>
            </label>
            <Grid columns={2}>
              <Grid.Column width={5}>
                <input
                  hidden
                  type="file"
                  accept="image/*;capture=camera"
                  ref={(ref) => {
                    uploadRef = ref;
                  }}
                  onChange={({
                    target: {
                      validity,
                      files: [file],
                    },
                  }) => {
                    if (validity.valid) handleFile(file, form);
                  }}
                />
                <Button onClick={() => uploadRef.click()} secondary>
                  Durchsuchen
                </Button>
              </Grid.Column>
              <Grid.Column width={11} className="file-names">
                <div className="wrapper">
                  {form.values.pictures.map((p, i) => {
                    return i === form.values.pictures.length - 1 ? `${p.name}` : `${p.name}, `;
                  })}
                </div>
                <ErrorMessage component="div" className="smalltext error" name="pictures" />
              </Grid.Column>
            </Grid>
          </span>
          <br /> {/* TODO: Fix inline spacing */}
          <TextArea name="comment" label="Kommentar (optional)" />
          <div className="formfooter">
            <p className="disclaimer smalltext">
              <span>*</span> Pflichtfelder
            </p>
            <Button.Submit disabled={form.isSubmitting}>Abschicken</Button.Submit>
          </div>
        </>
      )}
    </Form>
  );
};

ReclamationForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default Reclamation;
