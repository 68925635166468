import gql from 'graphql-tag';

export const CREATE_REVIEW = gql`
  mutation AddReview(
    $salesOrderId: ID!
    $salesOrderNumber: String!
    $customerId: ID!
    $customerNumber: String!
    $ignored: Boolean!
    $orderRating: Int
    $deliveryTimeRating: Int
    $deliveryCompletionRating: Int
    $freshnessRating: Int
    $descriptionRating: Int
    $ratingText: String
  ) {
    addReview(
      salesOrderId: $salesOrderId
      salesOrderNumber: $salesOrderNumber
      customerId: $customerId
      customerNumber: $customerNumber
      ignored: $ignored
      orderRating: $orderRating
      deliveryTimeRating: $deliveryTimeRating
      deliveryCompletionRating: $deliveryCompletionRating
      freshnessRating: $freshnessRating
      descriptionRating: $descriptionRating
      ratingText: $ratingText
    ) {
      success
    }
  }
`;
