import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Message, Header } from 'semantic-ui-react';
import { Button, Form, Input } from 'formik-semantic-ui';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { RESET_PASSWORD_MUTATION } from '../queries/AuthenticationQueries';
import AuthContainer from './AuthContainer';
import removeGQLError from '../helpers/remove-gql-error';

class ResetPasswordForm extends Component {
  passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Erforderlich')
      .min(8, 'Das Passwort muss mindestens 8 Zeichen haben'),
    passwordConfirm: Yup.string()
      .required('Erforderlich')
      .oneOf([Yup.ref('password'), null], 'Die Passwörter müssen übereinstimmen')
      .min(8, 'Das Passwort muss mindestens 8 Zeichen haben'),
  });

  constructor() {
    super();
    this.state = {
      errorMessage: null,
      isSuccessful: false,
      hasSubmitted: false,
    };
  }

  _handleSubmit = async (mutation, token, values, formikApi) => {
    formikApi.setSubmitting(true);
    // Make API Call
    mutation({
      variables: {
        token,
        password: values.password,
      },
    })
      .then(() => {
        this.setState({ isSuccessful: true });
      })
      .catch((e) => {
        this.setState({ errorMessage: removeGQLError(e.message) });
      })
      .finally(() => formikApi.setSubmitting(false));
  };

  render() {
    const { errorMessage, isSuccessful, hasSubmitted } = this.state;
    const { passwordSchema, props } = this;
    const { token } = props.match.params;

    return (
      <AuthContainer>
        <Mutation mutation={RESET_PASSWORD_MUTATION}>
          {(resetPassword) => (
            <Card className="card-auth">
              <Card.Content>
                <Form
                  initialValues={{
                    password: '',
                    passwordConfirm: '',
                  }}
                  // bind mutation and token to _handleSubmit so they can be accessed
                  // eslint-disable-next-line
                  onSubmit={this._handleSubmit.bind(this, resetPassword, token)}
                  validationSchema={passwordSchema}
                  validateOnBlur={hasSubmitted}
                >
                  <Grid stackable>
                    <Grid.Column>
                      <Header as="h1">Passwort zurücksetzen</Header>
                      {isSuccessful && (
                        <>
                          <Message
                            positive
                            header="Yay!"
                            content="Das Passwort wurde erfolgreich geändert."
                          />
                          <Link to="/login">Zum Login</Link>
                        </>
                      )}
                      {errorMessage && <Message negative header="Hoppla!" content={errorMessage} />}
                      {!isSuccessful && (
                        <>
                          <Input
                            label="Neues Passwort"
                            name="password"
                            inputProps={{
                              type: 'password',
                            }}
                          />
                          <Input
                            label="Neues Passwort wiederholden"
                            name="passwordConfirm"
                            inputProps={{
                              type: 'password',
                            }}
                          />
                          <br />
                          <Button.Submit onClick={() => this.setState({ hasSubmitted: true })}>
                            Absenden
                          </Button.Submit>
                        </>
                      )}
                    </Grid.Column>
                  </Grid>
                </Form>
              </Card.Content>
            </Card>
          )}
        </Mutation>
      </AuthContainer>
    );
  }
}

ResetPasswordForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default ResetPasswordForm;
