import { Cookies } from 'react-cookie';
import config from '../config';

class Auth {
  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

  static isUserAuthenticated() {
    return localStorage.getItem('token') !== null;
  }

  static deauthenticateUser() {
    const cookies = new Cookies();
    localStorage.removeItem('token');
    cookies.remove('token', { path: '/', domain: config.cookieDomain });
  }

  static getToken() {
    return localStorage.getItem('token');
  }
}

export default Auth;
