/* eslint jsx-a11y/label-has-associated-control: 0 */
/* eslint jsx-a11y/label-has-for: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { Form, Checkbox, Button } from 'formik-semantic-ui';
import * as Yup from 'yup';
import moment from 'moment';
import { client } from '../index';
import { GET_USER, UPDATE_USER } from '../queries/UserQueries';
import { SETUP_QUERY } from '../queries/SetupQueries';
import config from '../config';

const TermsModal = ({ defaultOpen, includeDataPrivacy, includeTGC }) => {
  const [open, setOpen] = useState(defaultOpen);

  const termsSchema = Yup.object().shape({
    ...(includeTGC && {
      tgcAccepted: Yup.boolean().oneOf([true], 'Bitte akzeptieren Sie unsere AGB'),
    }),
    ...(includeDataPrivacy && {
      dataPrivacyAccepted: Yup.boolean().oneOf(
        [true],
        'Bitte akzeptieren Sie unsere Datenschutzbestimmungen'
      ),
    }),
  });

  const handleSubmit = async () => {
    try {
      await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          ...(includeTGC && { tgcAccepted: moment().format() }),
          ...(includeDataPrivacy && { dataPrivacyAccepted: moment().format() }),
        },
        refetchQueries: [{ query: GET_USER }, { query: SETUP_QUERY }],
      });
    } catch (e) {
      throw new Error(e);
    }
    setOpen(false);
  };

  return (
    <Modal open={open} className="notification-box brown">
      <Modal.Header>
        Neue {includeTGC ? 'AGBs ' : ''} {includeTGC && includeDataPrivacy ? 'und ' : ''}{' '}
        {includeDataPrivacy ? 'Datenschutzbestimmungen' : ''}
      </Modal.Header>
      <Modal.Content>
        <Form
          initialValues={{
            ...(includeTGC && { tgcAccepted: false }),
            ...(includeDataPrivacy && { dataPrivacyAccepted: false }),
          }}
          validationSchema={termsSchema}
          onSubmit={handleSubmit}
        >
          <Modal.Description>
            <h4>
              Bitte akzeptieren Sie unsere aktualisierten{' '}
              {includeTGC ? 'Allgemeinen Geschäftsbedingungen ' : ''}
              {includeTGC && includeDataPrivacy ? 'und ' : ''}
              {includeDataPrivacy ? 'Datenschutzerklärung' : ''}
            </h4>
            {includeTGC && (
              <Checkbox
                name="tgcAccepted"
                id="field_checkbox_tgcAccepted"
                label={
                  <label htmlFor="field_checkbox_tgcAccepted">
                    Ich habe die{' '}
                    <a
                      href={`${config.website.baseURL}/business-agb?utm_source=businessapp&utm_medium=email&utm_campaign=businessapp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Allgemeinen Geschäftsbedingungen
                    </a>{' '}
                    gelesen und stimme ihnen hiermit zu.
                  </label>
                }
              />
            )}
            {includeDataPrivacy && (
              <Checkbox
                name="dataPrivacyAccepted"
                id="field_checkbox_dataPrivacyAccepted"
                label={
                  <label htmlFor="field_checkbox_dataPrivacyAccepted">
                    Ich habe die{' '}
                    <a
                      href={`${config.website.baseURL}/datenschutz?utm_source=businessapp&utm_medium=email&utm_campaign=businessapp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Datenschutzerklärung
                    </a>{' '}
                    zur Kenntnis genommen.
                  </label>
                }
              />
            )}
          </Modal.Description>
          <Modal.Actions>
            <a href="https://querfeld.bio">
              <Button secondary>Züruck zur Startseite</Button>
            </a>

            <Button.Submit positive>Bestätigen</Button.Submit>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

TermsModal.propTypes = {
  defaultOpen: PropTypes.bool.isRequired,
  includeDataPrivacy: PropTypes.bool.isRequired,
  includeTGC: PropTypes.bool.isRequired,
};

export default TermsModal;
