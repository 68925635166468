import React from 'react';
import { Link } from 'react-router-dom';

export const generalFAQs = [
  {
    key: 'general-anmeldung',
    title: 'Wie melde ich mich für das Querfeld-Angebot an?',
    content: {
      content: (
        <div>
          <p>
            Um das Online-Angebot von Querfeld abrufen zu können, brauchen Sie ein aktiviertes
            Kundenkonto. Dieses Konto können Sie <Link to="/apply">hier</Link> oder im
            Anmeldefenster unter “Kundenkonto registrieren” anlegen. Füllen Sie das Anmeldeformular
            mit den Daten zu Ihrem Betrieb aus, insbesondere die Lieferadresse ist für uns wichtig
            um festzustellen, ob Sie in einer unserer Lieferregionen liegen. Unsere
            Mitarbeiter*innen prüfen Ihre Anmeldung und aktivieren Ihren Zugang, wenn keine
            Rückfragen bestehen. Anschließend bekommen Sie von uns eine Aktivierungsbestätigung.
            Darin finden Sie einen Link, mit dem Sie Ihr Passwort festlegen können und das war’s.
            Jetzt können Sie sich bei uns einloggen und unsere krummen Schätze durchsuchen.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'general-sortiment',
    title: 'Wieso wechselt das Sortiment wöchentlich?',
    content: {
      content: (
        <div>
          <p>
            Generell unterliegt der Handel mit Obst und Gemüse saisonalen Schwankungen und eine
            Vielzahl von Faktoren beeinflusst die Lieferfähigkeit der Lieferanten. Darüber hinaus
            liefern wir Ihnen insbesondere das Obst und Gemüse, das lediglich aufgrund von
            ästhetischen Besonderheiten aussortiert wurde oder aufgrund von Überschüssen nicht in
            den Handel gelangt. Daher wechselt unsere Palette wöchentlich basierend auf dem Angebot,
            das wir von unseren Erzeuger*innen bekommen. Mit unserem Angebot wollen wir dafür
            sorgen, dass natürlich Vielfalt wieder geschätzt und weniger Lebensmittel verschwendet
            werden. Um Ihrer Küche trotzdem ein verlässliches Angebot zu bieten, nehmen wir
            zusätzlich Basis-Artikel aus dem Bio-Segment auf. Auch bei dieser Ware achten wir auf
            bedarfsgerechte und nachhaltige Lieferwege. Mit Ihrer Bestellung sorgen Sie dafür, dass
            Vielfalt wieder geschätzt und weniger Lebensmittel verschwendet werden.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'general-lieferregionen',
    title: 'Welche Regionen beliefert Querfeld?',
    content: {
      content: (
        <div>
          <p>
            Aktuell beliefert Querfeld die Regionen Berlin, München und Nordrhein-Westfalen. In
            naher Zukunft wollen wir zusätzlich den Raum Hamburg und Frankfurt am Main mit Produkten
            aus der Region und dem Ausland beliefern. Als aufstrebendes Unternehmen sind wir sehr
            daran interessiert, auch weitere Regionen in Deutschland mit Obst & Gemüse zu versorgen.
            Tragen Sie sich in unseren{' '}
            <a
              href="https://querfeld.bio/aussortiertes-obst-gemuese-kaufen#angebotsverteiler"
              target="_blank"
              rel="noopener noreferrer"
            >
              Newsletter
            </a>
            , um unser wachsendes Angebot nicht zu verpassen.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'general-informations',
    title: 'Was muss ich bei meiner Bestellung beachten?',
    content: {
      content: (
        <div>
          <p>
            <strong>Wöchentliches Bestellfenster:</strong> Bestellungen können nur zwischen Montag
            10 Uhr und Mittwoch 12 Uhr aufgegeben werden. Dies liegt daran, dass unsere
            Erzeuger*innen im In- und Ausland noch Zeit benötigen die Waren zu ernten und die
            entsprechende Logistik von uns organisiert werden muss.
          </p>
          <p>
            <strong>Mindestbestellwert & Bestellgrenze:</strong> Es gibt keine Grenze für den Umfang
            Ihrer Bestellung. Teils kann es jedoch vorkommen, dass wir als “begrenzte Verfügbarkeit”
            markierte Erzeugnisse anbieten und hier der volle Umfang der von Ihnen bestellten Menge
            nicht lieferbar ist. In diesen Fällen werden Sie jedoch rechtzeitig kontaktiert. Es gibt
            außerdem einen Mindestbestellmenge von 75 kg, um einerseits eine sinnvolle Auslastung
            der Zustellfahrzeuge und andererseits eine kostenlose Lieferung garantieren zu können.
          </p>
          <p>
            <strong>Gebindegröße:</strong> Da wir keine Lagerbestände führen, können lediglich ganze
            Kisten bestellt werden. Der Inhalt einer Kiste ist definiert durch die vom Erzeuger
            gewählte Gebindegröße.
          </p>
          <p>
            <strong>Liefergebühr:</strong> Da wir einen Mindestbestelltwert voraussetzen, verzichten
            wir auf eine Liefergebühr.
          </p>
          <p>
            <strong>Pfandkosten:</strong> Pfand wird auf Grundlage der unterzeichneten Lieferscheine
            berechnet und taucht somit nicht in der Aufstellung im Online-Shop auf. Ihre
            wöchentliche Rechnung weist den ausgelieferten Pfand aus.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'general-lieferzeit',
    title: 'Wann kann meine Bestellung geliefert werden?',
    content: {
      content: (
        <div>
          <p>
            Wir können leider nicht garantieren, dass die Ware in Ihrem präferierten Lieferzeitraum
            geliefert wird. Es handelt sich hierbei lediglich um einen Richtwert für unsere
            Logistik, der uns bei der Einteilung der Stopps in der Tourenplanung hilft. Bitte planen
            Sie nie so, dass Sie die Ware zwingend zum präferierten Lieferfenster benötigen.
          </p>
        </div>
      ),
    },
  },
];

export const shopFAQs = [
  {
    key: 'shop-cart',
    title: 'Wie lege ich Artikel in den Warenkorb?',
    content: {
      content: (
        <div>
          <p>
            In unserem Online-Shop können Sie schnell und einfach über eine direkte Eingabe in das
            Mengenfeld die gewünschte Menge Ihrer Wunschartikel angeben. Bei Differenzen zur
            Gebindegröße des Artikels werden Sie darauf hingewiesen. Abschließend klicken Sie unten
            rechts auf “Zum Warenkorb hinzufügen”. Das war’s! Die ausgewählten Produktmengen finden
            Sie jetzt im Warenkorb. Neben erscheint ein kleiner grüner Einkaufswagen neben den
            ausgewählten Artikeln in der Liste. Nun können Sie sich den Warenkorb anzeigen lassen
            und den Bestellvorgang abschließen oder Sie durchstöbern unseren Shop nach weiteren
            leckeren Produkten. Wenn Sie in unserer Artikelübersicht die Menge eines Artikels ändern
            oder weitere Artikel hinzufügen wollen, geben Sie einfach die gewünschte Menge an und
            klicken anschließend unten rechts auf “Warenkorb aktualisieren”.
          </p>
          <p>
            <strong>Tipp:</strong> In unserem Shop können Sie Mengen basierend auf der Gebindegröße
            des Artikels bestellen. Nutzen Sie die “+” und “-” Schaltflächen, um schnell auf die
            passende Menge zu kommen.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-filter',
    title: 'Wie kann ich das Angebot nach Kriterien filtern?',
    content: {
      content: (
        <div>
          <p>
            In der Produktübersicht können Sie die Artikel unter “Herkunft” nach Regional, National
            und International filtern. Außerdem können Sie unter “Art der Produkte” die Artikel nach
            Obst, Gemüse und Fruchtgemüse auflisten. Zusätzlich steht Ihnen eine Suchleiste zur
            Verfügung, in die Sie ihren Wunschartikel direkt eingeben können. Um alle Suchfilter
            zurückzusetzen klicken Sie einfach auf “Suchfilter zurücksetzen”.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-transport',
    title: 'Wie werden die Artikel verpackt und transportiert?',
    content: {
      content: (
        <div>
          <p>
            Da wir keine Lagerbestände führen, können bei uns lediglich ganze Kisten bestellt
            werden. Der Inhalt einer Kiste wird definiert durch die Gebindegröße des Artikels. Wir
            arbeiten mit Napf, Ifco, Europool als Pfandsystem, nutzen aber auch Kartons um die Ware
            zu verpacken. Wir versuchen dabei unnötige Abfallerzeugung zu vermeiden. Je nach
            Bestellmenge, wird Ihre Bestellung von unseren Logistikern auf einer Palette oder in
            einzelnen Kisten auf einem Rolli zum Tausch zugestellt.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-pfand',
    title: 'Was mache ich mit meinem Leergut/ Pfand?',
    content: {
      content: (
        <div>
          <p>
            Bitte geben Sie das Pfandgut dem Logistiker bei der nächsten Lieferung mit und stellen
            Sie sicher, dass die korrekte Anzahl der jeweiligen Kisten auf dem Lieferschein notiert
            wird.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-letzte-bestellung',
    title: 'Wo kann ich meine letzte Bestellung einsehen?',
    content: {
      content: (
        <div>
          <p>
            Eine Übersicht über ihre letzten Bestellungen können Sie unter “Account / Bestellungen”
            (oben rechts) aufrufen. Hier finden Sie die Auftrags-Nummer, die Auftragsbestätigung,
            sowie das Bestell- und Lieferdatum zu ihren Bestellungen. Außerdem haben Sie die
            Möglichkeit innerhalb unseres Bestellfensters Ihre Bestellung anzupassen.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-bezahlung',
    title: 'Wie funktioniert die Bezahlung bei Querfeld?',
    content: {
      content: (
        <div>
          <p>
            Als Zahlungsoptionen bieten wir Ihnen aktuell Zahlung auf Rechnung und per SEPA
            Lastschriftverfahren an. Falls Ihre Rechnungsadresse noch nicht in unserem System
            hinterlegt ist, werden Sie während der Bestellabwicklung aufgefordert, Ihre Daten zu
            vervollständigen.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-lieferadresse+rechnungsadresse',
    title: 'Kann ich unterschiedliche Rechnungs- und Lieferadressen angeben?',
    content: {
      content: (
        <div>
          <p>
            Es ist möglich, unterschiedliche Rechnungs- und Lieferadressen anzugeben. Falls Sie Ihre
            Adressen anpassen bzw. ergänzen wollen, wenden Sie sich bitte per E-Mail an unseren
            Kundensupport <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>
            .
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-Kontoinformationen',
    title: 'Wie ändere ich meine Kontoinformationen?',
    content: {
      content: (
        <div>
          <p>
            Unter “Account / Kontoinformationen” (oben rechts) können Sie Ihre von unserem System
            gespeicherten Daten abrufen. Zur Zeit können Sie Ihre Daten auf unserer Plattform nur
            einsehen. Änderungen können Sie momentan lediglich per Mail an
            kundenservice@querfeld.bio veranlassen. Bitte bedenken Sie, dass die Anpassung Ihrer
            Daten etwas Zeit in Anspruch nehmen kann.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-bestellung-bearbeiten',
    title: 'Kann ich eine Bestellung ändern oder stornieren?',
    content: {
      content: (
        <div>
          <p>
            Innerhalb unseres Bestellfensters können Sie Ihre Bestellungen unter “Account /
            Bestellungen” anpassen. Hier finden Sie auch in Zukunft die Möglichkeit, Ihre Bestellung
            zu stornieren. Wir bitten um Verständnis, dass wir Bestellungen, die bereits zum
            Erzeuger versandt wurden, nicht mehr geändert oder storniert werden können. Falls Sie
            Probleme mit Ihrer Bestellung haben, wenden Sie sich bitte an{' '}
            <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-probleme',
    title: 'Was mache ich, wenn ich Probleme bei meiner Bestellung habe?',
    content: {
      content: (
        <div>
          <p>
            Es tut uns leid, dass Sie Probleme mit Ihrer Bestellung haben. Bei technischen Problemen
            mit unserer Online-Plattform, stellen Sie bitte sicher, dass Ihr Browser auf dem
            aktuellsten Stand ist und Sie eine stabile Internetverbindung haben. Falls Sie weiterhin
            Schwierigkeiten haben, unseren Online-Service zu nutzen, beschreiben Sie uns Ihre
            Probleme und das System das Sie nutzen per E-Mail an{' '}
            <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a> und ein*e
            Querfeld-Mitarbeiter*in kümmert sich in Kürze um Ihr Anliegen. Wenn der Shop erreichbar
            ist und nur bestimmte Funktionen nicht verfügbar sind, können Sie auch direkt über den
            “Support”-Bereich oben rechts eine Nachricht an uns schicken.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-lieferung-nicht-erhalten',
    title: 'Ich habe meine Lieferung nicht erhalten. Was ist zu tun?',
    content: {
      content: (
        <div>
          <p>
            Sollte es passieren, dass Ihre Bestellung zum vereinbarten Liefertermin nicht geliefert
            worden ist, melden Sie sich bitte umgehend telefonisch bei unserem Kundenservice 030-340
            603 470.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-beanstandungen',
    title: 'Was kann ich machen, wenn die gelieferte Ware nicht in Ordnung ist?',
    content: {
      content: (
        <div>
          <p>
            Bei Beanstandungen zu Ihrer Lieferung, setzen Sie sich bitte mit unserem Kundenservice
            in Verbindung. Machen Sie hierfür von der zu beanstandenden Ware ein Foto und senden Sie
            es uns per Mail mit Ihrem Anliegen an{' '}
            <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>. Ein
            fleißige*r Querfeld-Mitarbeiter*in wird sich zeitnah bei Ihnen melden und gemeinsam mit
            Ihnen eine Lösung finden.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-zugangsdaten',
    title: 'Ich habe meine Zugangsdaten vergessen. Was muss ich tun?',
    content: {
      content: (
        <div>
          <p>
            Klicken Sie im ”Anmelde-Fenster” auf ”Passwort vergessen” und geben Sie dort die
            E-Mail-Adresse von ihrem Querfeld-Benutzerkonto an. An diese Adresse wird Ihnen
            anschließend ein automatisch generierter Link gesendet, mit dem Sie ein neues Passwort
            festlegen können.
          </p>
          <p>
            Falls Sie die E-Mail-Adresse ihres Querfeld-Benutzerkontos vergessen haben, wenden Sie
            sich bitte direkt an unseren Kundenservice{' '}
            <a href="mailto:kundenservice@querfeld.bio">kundenservice@querfeld.bio</a>.
          </p>
        </div>
      ),
    },
  },
  {
    key: 'shop-verkaufen',
    title: 'Kann ich als Erzeuger meine Ware über Querfeld anbieten? ',
    content: {
      content: (
        <div>
          <p>
            Gerne! Wir sind sehr daran interessiert, unser Netzwerk an Erzeugern in unseren
            Liefergebieten weiter auszubauen und haben ein aufgeschlossenes Netzwerk an Kund*innen.
            So wird es Ihnen ermöglicht Ihre gesamte Ernte abzusetzen und gemeinsam können wir etwas
            gegen Lebensmittelverluste unternehmen. Ob II. Wahl, Ausschuss, Verarbeitungs-,
            Industrie- oder B-Ware, für uns ist Geschmack keine Frage des Aussehens. Weitere
            Informationen finden Sie auf unserer{' '}
            <a
              href="https://querfeld.bio/b-ware-verkaufen"
              target="_blank"
              rel="noopener noreferrer"
            >
              Seite für Erzeuger
            </a>
            . Wir freuen uns von Ihnen zu hören.
          </p>
        </div>
      ),
    },
  },
];
