import React, { Component } from 'react';
import './App.css';
import '../semantic/dist/semantic.css';
import './styles/main.scss';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withAnalytics } from './components/withAnalytics';
import PrivateRoute from './components/PrivateRoute';
import RegistrationForm from './components/RegistrationForm';
import Shop from './components/Shop';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import HomePage from './components/HomePage';
import BillingFormContainer from './components/BillingFormContainer';
import Checkout from './components/Checkout';
import AccountDetails from './components/AccountDetails';
import OrderHistory from './components/OrderHistory';
import OrderDetails from './components/OrderDetails';
import CartStore from './services/CartStoreService';
import Contact from './components/Contact';
import FAQ from './components/FAQ';
import Reclamation from './components/Reclamation';
import ErrorBoundary from './components/ErrorBoundary';
import Login from './components/Login';
import Auth from './services/AuthService';
import { client } from './index';
import { UPDATE_COOKIE_CONSENT } from './queries/UserQueries';

class App extends Component {
  componentDidMount = () => {
    // hydrate Apollo cache with stored cart
    CartStore.hydrateCache();

    // Listener to update cookie settings if user logged in
    ['CookieScriptAccept', 'CookieScriptAcceptAll', 'CookieScriptReject'].forEach((e) => {
      window.addEventListener(e, () => {
        if (!window.rudderLoaded) return;
        if (Auth.isUserAuthenticated()) {
          client.mutate({
            mutation: UPDATE_COOKIE_CONSENT,
          });
        }
      });
    });

    // add event listener to save cart to localStorage
    // when user leaves/refreshes the page
    window.addEventListener('beforeunload', CartStore.saveCacheToCartStore);
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', CartStore.saveCacheToCartStore);

    // saves if component has a chance to unmount
    CartStore.saveCacheToCartStore();
  };

  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Querfeld Shop</title>
        </Helmet>
        <div className="App-container">
          <ErrorBoundary>
            <Switch>
              <Route exact path="/register/:token" component={withAnalytics(RegistrationForm)} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={withAnalytics(ForgotPasswordForm)} />
              <Route exact path="/reset/:token" component={withAnalytics(ResetPasswordForm)} />
              <PrivateRoute path="/shop" component={withAnalytics(Shop)} />
              <PrivateRoute path="/billing" component={withAnalytics(BillingFormContainer)} />
              <PrivateRoute path="/checkout" component={withAnalytics(Checkout)} />
              <PrivateRoute path="/account-details" component={withAnalytics(AccountDetails)} />
              <PrivateRoute path="/order-history" component={withAnalytics(OrderHistory)} />
              <PrivateRoute
                path="/order-details/:orderId"
                component={withAnalytics(OrderDetails)}
              />
              <PrivateRoute path="/contact" component={withAnalytics(Contact)} />
              <PrivateRoute path="/FAQ" component={withAnalytics(FAQ)} />
              <PrivateRoute path="/reclamation" component={withAnalytics(Reclamation)} />
              <Route component={withAnalytics(HomePage)} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default App;
