import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Message, Header } from 'semantic-ui-react';
import { Button, Form, TextArea, Dropdown } from 'formik-semantic-ui';
import * as Yup from 'yup';
import { client } from '../index';
import { GET_USER } from '../queries/UserQueries';
import { SUPPORT_CONTACT_MUTATION } from '../queries/SupportQueries';

const Contact = (props) => {
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const referredState = props.location.state;

  const ticketSchema = Yup.object().shape({
    topic: Yup.string().required('Erforderlich'),
    request: Yup.string().required('Erforderlich'),
  });

  const handleSubmit = async (values, formikApi) => {
    formikApi.setSubmitting(true);

    try {
      const { currentUser } = await client.readQuery({ query: GET_USER });

      const variables = {
        customerId: currentUser.customerId,
        topic: values.topic,
        request: values.request,
        error:
          referredState && referredState.errorData
            ? referredState.errorData.error.toString()
            : undefined,
      };

      await client.mutate({ mutation: SUPPORT_CONTACT_MUTATION, variables });
      setSuccess(true);
      setErrorMessage(null);
    } catch (e) {
      setSuccess(false);
      setErrorMessage(e);
    } finally {
      formikApi.resetForm();
      formikApi.setSubmitting(false);
    }
  };

  return (
    <Card className="card-support">
      <Card.Content>
        <div className="formsection dark-bg" id="feedback-form">
          <Form
            className="supportform"
            initialValues={{
              topic: referredState && referredState.contactType ? referredState.contactType : '',
              request: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={ticketSchema}
            validateOnBlur={false}
          >
            <Grid stackable>
              <Grid.Column>
                <Header as="h2">Kontaktformular</Header>
                <p>
                  Wir sind stets darum bemüht unseren Service zu verbessern.
                  <br />
                  Falls Sie Verbesserungswünsche, Anregungen oder offene Fragen haben,
                  <br />
                  freuen wir uns auf Ihre Mitteilung. Vielen Dank.
                </p>
                {success && (
                  <Message
                    positive
                    header="Vielen Dank für Ihr Feedback!"
                    content="Der Teufel steckt bekanntlich im Detail und nur mit Ihrer Hilfe
                    können wir auch die letzten Fehler oder Ungereimtheiten im Shop ausmerzen.
                    Wir schauen uns Ihr anliegen an und melden uns schnellstmöglich bei Ihnen."
                  />
                )}
                {errorMessage && <Message negative header="Hoppla!" content={errorMessage} />}
                <Dropdown
                  label="Thema Ihrer Anfrage"
                  name="topic"
                  inputProps={{ placeholder: 'Thema auswählen' }}
                  options={[
                    { value: 'shop_bug', text: 'Der Shop funktioniert nicht richtig' },
                    {
                      value: 'suggestion',
                      text: 'Ich möchte einen Verbesserungsvorschlag einreichen',
                    },
                  ]}
                />
                <TextArea label="Ihre Nachricht" name="request" />
              </Grid.Column>
            </Grid>
            <div className="formfooter">
              <Button.Submit>Abschicken</Button.Submit>
            </div>
          </Form>
        </div>
      </Card.Content>
    </Card>
  );
};

Contact.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      contactType: String,
      errorData: PropTypes.shape({
        error: PropTypes.string,
        errorInfo: PropTypes.string,
      }),
    }),
  }),
};

Contact.defaultProps = {
  location: undefined,
};

export default Contact;
